import React from "react"
import { Routes, Route } from "react-router-dom"

import Dashboard from "../pages/Admin/Dashboard/index"
import Influencers from "../pages/Admin/InfluencerList"
import InfluencersAll from "../pages/Admin/InfluencerListAll"
import Pendencias from "../pages/Admin/InfluencerListPendentes"
import Saques from "../pages/Admin/InfluencerListSaques"
import Arquivados from "../pages/Admin/InfluencerListArquivados"
import SemSucesso from "../pages/Admin/InfluencerListSemSucesso"
import Upload from "../pages/Admin/FormUpload/FormUpload"
import Tracking from "../pages/Admin/TrackingCode/index"
import Tracking2 from "../pages/Admin/TrackingCode2/invoices-list"
import TrackingPerfil from "../pages/Admin/TrackingPerfil"

import InfluencerProfile from "pages/Grupos/Calendar/index"
import AssessorProfile from "pages/Admin/AssessorProfile/index"

import AssessorProfileOrlandin from "pages/Admin/BetanoOrlandinho"
import AssessorProfileFelipemnz from "pages/Admin/BetanoFelipemnz"

import AssessorRelatorio from "pages/Admin/AssessorRelatorio/index"
import ColaboradoresProfile from "pages/Admin/ColaboradoresProfile/ContactsProfile/contacts-profile"
import ColaboradoresProfiles from "pages/Admin/CandidateList"
import ColaboradorArquivado from "pages/Admin/ColaboradorArquivado"

import PrimeiraAbordagem from "pages/Admin/InfluencerListPrimeiraAbordagem"
import ConversaIniciada from "pages/Admin/InfluencerListConversaIniciada"
import ConviteWhats from "pages/Admin/InfluencerListConvitesWhats"
import WhatsCaptado from "pages/Admin/InfluencerListWhatsCaptado";
import WhatsRespondidos from "pages/Admin/InfluencerListWhatsRespondidos";
import FollowUp from "pages/Admin/InfluencerListFollowUp";

import DashboardJob from "pages/Admin/DashboardJob"
import MetricasColaborador from "pages/Admin/MetricasColaborador"
import ListGeneratePaymentsViraw from "pages/Admin/ListGeneratePaymentsViraw";
import GeneratePayments from "pages/Admin/ListGeneratePayments";
import Desarquivar from "pages/Admin/InfluencerListPendentesDesarquivar";
import AgendarSala from "pages/Admin/AgendarSala";
import DashboardSuperBet from "pages/Admin/DashboardSuperBet";
import DashboardXpbet from "pages/Admin/DashboardXpbet";
import DashboardApg25 from "pages/Admin/DashboardApostaGanha25";
import ListGeneratePaymentsDay from "pages/Admin/ListGeneratePaymentsDay"
import ListGeneratePaymentsAntecipado from "pages/Admin/ListGeneratePaymentsAntecipado"
import InfluencerListNegotiating from "pages/Admin/InfluencerListNegotiating"
import Ranking from "pages/Admin/Ranking"
import AssessoresAll from "../pages/Admin/AssessorListAll"
import AssessoresOther from "../pages/Admin/AssessorListOther"
import Scheduling from "../pages/Admin/Scheduling"
import FinanceiroViraw from "../pages/Admin/FinanceiroViraw"
import Telao from "../pages/Admin/Telao"

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />} />
      <Route index path="/influencers" element={<Influencers />} />
      <Route index path="/influencers/all" element={<InfluencersAll />} />
      <Route index path="/assessores/all" element={<AssessoresAll />} />
      <Route index path="/assessores/outros" element={<AssessoresOther />} />
      <Route index path="/pendentes" element={<Pendencias />} />
      <Route index path="/pendentes/desarquivar" element={<Desarquivar />} />
      <Route index path="/saques-influencers" element={<Saques />} />
      
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/primeira-abordagem" element={<PrimeiraAbordagem />} />
      <Route index path="/conversa-iniciada" element={<ConversaIniciada />} />
      <Route index path="/convite-whats" element={<ConviteWhats />} />
      <Route index path="/whats-captados" element={<WhatsCaptado />} />
      <Route index path="/whats-respondidos" element={<WhatsRespondidos />} />
      <Route index path="/follow-up" element={<FollowUp />} />

      <Route index path="/semsucesso" element={<SemSucesso />} />
      <Route index path="/influencer/:id" element={<InfluencerProfile />} />
      <Route index path="/assessor/:id" element={<AssessorProfile />} />
      <Route index path="/assessor/outros/orlandinho" element={<AssessorProfileOrlandin />} />
      <Route index path="/assessor/outros/felipemnz" element={<AssessorProfileFelipemnz />} />
      <Route index path="/assessor/relatorios/:id" element={<AssessorRelatorio />} />
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route index path="/form-uploads" element={<Upload />} />
      <Route index path="/tracking-codes" element={<Tracking />} />
      <Route index path="/tracking-codes2" element={<Tracking2 />} />
      <Route index path="/dashboard-aposta" element={<DashboardJob />} />
      <Route index path="/dashboard-super" element={<DashboardSuperBet />} />
      <Route index path="/dashboard-xpbet" element={<DashboardXpbet />} />
      <Route index path="/dashboard-apg25" element={<DashboardApg25 />} />
      <Route index path="/generatepayments" element={<GeneratePayments />} />
      <Route index path="/generatepaymentsviraw" element={<ListGeneratePaymentsViraw />} />
      <Route index path="/scheduling" element={<Scheduling />} />
      <Route index path="/agendar-sala" element={<AgendarSala />} />
      <Route index path="/generatepaymentsday" element={<ListGeneratePaymentsDay />} />
      <Route index path="/generatepaymentsantecipado" element={<ListGeneratePaymentsAntecipado />} />
      <Route index path="/negociando" element={<InfluencerListNegotiating />} />
      <Route index path="/ranking" element={<Ranking />} />
      <Route index path="/financeiro" element={<FinanceiroViraw />} />
      <Route index path="/telao" element={<Telao />} />

      <Route
        index
        path="/tracking-perfil/:username"
        element={<TrackingPerfil />}
      />
      {/* <Route index path="/colaboradores" element={<Colaboradores />}  /> */}
      <Route
        index
        path="/colaboradores/:id"
        element={<ColaboradoresProfile />}
      />
      <Route index path="/colaboradores" element={<ColaboradoresProfiles />} />
      {/* <Route index path="/colaboradores-metricas" element={<MetricasColaborador />}  /> */}
      <Route index path="/desativados" element={<ColaboradorArquivado />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  )
}
