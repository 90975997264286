import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Form, FormGroup, Label, Input, ModalFooter, Col, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "contexts/auth";
import { StateContext } from "contexts/states";
import ptBR from 'date-fns/locale/pt-BR';
import Toastr from "../../../components/Ui/Notifications";

import api from "config/api";

const AddPaymentModal = ({idInfluencer }) => {
    const [paymentValue, setPaymentValue] = useState("");
    const { addValue, setValue } = useContext(StateContext)
    const { user } = useContext(AuthContext)
    const [paymentDate, setPaymentDate] = useState(null);
    const [showDateWarning, setShowDateWarning] = useState(false);
    const [linkComprovante, setLinkComprovante] = useState("");

    function formatarValorEmReal(valor) {
        const parteInteira = Math.floor(valor);
        const parteDecimal = Math.round((valor - parteInteira) * 100);
      
        const parteInteiraFormatada = new Intl.NumberFormat('pt-BR').format(parteInteira);
        const valorFormatado = `R$ ${parteInteiraFormatada},${parteDecimal.toString().padStart(2, '0')}`;
      
        return valorFormatado;
      }

    const handleAddPayment = () => {
        if (!paymentDate) {
            setShowDateWarning(true);
            return;
        }

        postPayment(paymentValue, paymentDate, linkComprovante, idInfluencer)

        toggle()
    };

    const toggle = () => {
        setValue(!addValue);
    };


    async function postPayment (value, date, link, influencer){
        await api.post("/payments",{
            value: value,
            date: date,
            link: link,
            influencer: influencer,
            responsavel: user.userId
        },{
            headers:{
                Authorization: `Bearer ${user.token}`
            }
        }).then((response) =>{
            Toastr("success", "Pagamento adicionado com sucesso!")
        }).catch((error) =>{
            console.error(error);
            Toastr("error", "Algo errado aconteceu!")
        });
    }


    const formatarMoeda = (valor) => {
        const numeroLimpo = valor.replace(/[^0-9]/g, '');
    
        const valorNumerico = parseFloat(numeroLimpo) / 100 ;
    
        const numeroFormatado = valorNumerico.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        });
    
        return numeroFormatado;
      };

    return (
        <Modal isOpen={addValue} toggle={toggle}>
            <div className="modal-header">
                <h5 className="modal-title">Detalhes da Publicidade</h5>
                <button type="button" className="btn-close" onClick={toggle}></button>
            </div>
            <div className="modal-body">
                <Form>
                    <FormGroup>
                        <Label for="paymentLink">Descrição</Label>
                        <Input
                            type="text"
                            id="paymentLink"
                            placeholder="Detalhes da Publicidade"
                            value={linkComprovante}
                            onChange={(e) => setLinkComprovante(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label for="paymentValue">Valor do Pagamento</Label>
                                <Input
                                    type="text"
                                    id="paymentValue"
                                    placeholder="Digite o valor do pagamento"
                                    value={paymentValue}
                                    onChange={(e) => setPaymentValue(formatarMoeda(e.target.value))}
                                 
                                />
                            </Col>
                            <Col>
                                <Label for="paymentDate">Data do Pagamento</Label>
                                <DatePicker
                                    placeholder="dd/mm/aaaa"
                                    selected={paymentDate}
                                    onChange={(date) => setPaymentDate(date)}
                                    className="form-control"
                                    locale={ptBR}
                                    dateFormat="dd/MM/yyyy"
                                />
                                {showDateWarning && (
                                    <div className="text-danger mt-2">Por favor, selecione uma data.</div>
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </div>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Fechar
                </Button>
                <Button color="primary" onClick={handleAddPayment}>
                    Adicionar Publicidade
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddPaymentModal;
