import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import { Card, CardBody, Col, Container, Row, Input, Button } from 'reactstrap';
import Select from 'react-select';
import api from 'config/api';
import Spinners from 'components/Common/Spinner';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from './List';
import Toastr from "../../../components/Ui/Notifications";
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import 'jspdf-autotable';

const CollaboratorsList = () => {
    document.title = 'Influencers | Viraw Influencers';

    const [isLoading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const { user } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('');
    const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]);
    const [listPayments, setListPayments] = useState([]);
    const [listPaymentsOriginal, setListPaymentsOriginal] = useState([]);
    const [loadingRequests, setLoadingRequests] = useState(false);
    const [loadingExtract, setLoadingExtract] = useState(false);
    const [isMonday, setIsMonday] = useState(false);
    const [isExtract, setIsExtract] = useState(false);
    const [totalPayments, setTotalPayments] = useState(0);

    useEffect(() => {
        const hoje = new Date();
        const diaSemana = hoje.getDay();
        setIsMonday(diaSemana === 1 || diaSemana === 3);
        setIsExtract(diaSemana === 1 || diaSemana === 3);
        getSaques();
    }, [user.token]);

    const getSaques = async () => {
        try {
            const response = await api.get('/influencers/generetepayments', {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
    
            const data = response.data;
            setListPayments(data);
            setListPaymentsOriginal(data);
    
            // Calcular o total
            const total = data.reduce((sum, payment) => {
                const value = parseFloat(payment.Pagar);
                return !isNaN(value) ? sum + value : sum;
            }, 0);
            setTotalPayments(total);
    
            // Atualizar colaboradores únicos no filtro
            const uniqueResponsibles = Array.from(new Set(data.map(item => item.responsavel)));
            setColaboradoresGrupo(uniqueResponsibles);
        } catch (error) {
            console.error('Error fetching influencers:', error);
        }
    };

    const handleSectorChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            const selectedSectorValue = selectedOption.value;
            const filteredBySector = listPaymentsOriginal.filter(collaborator =>
                collaborator.responsavel === selectedSectorValue);
            setListPayments(filteredBySector);
        } else {
            setListPayments(listPaymentsOriginal);
        }
    };

    const handleSearchCollaborator = (value) => {
        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();

        const filteredCollaborators = listPaymentsOriginal.filter(collaborator => {
            const fullname = collaborator.influencer?.toLowerCase() || '';
            const username = collaborator.username?.toLowerCase() || '';
            return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);
        });
        setListPayments(filteredCollaborators);

        if (value.trim() === '') {
            setSelectedOption(null);
            setListPayments(listPaymentsOriginal);
        }
    };

    const fetchPayments = () => {
        setLoadingRequests(true);
        generatepayments();
    };
    
    const extractPayments = () => {
        setLoadingExtract(true);

        const paymentsByResponsavel = listPayments.reduce((acc, payment) => {
            if (payment.isEmitNote) {
                const responsavel = payment.responsavel || 'Sem Responsável';
                if (!acc[responsavel]) {
                    acc[responsavel] = [];
                }
                acc[responsavel].push({
                    Nome: payment?.DadosBancarios?.name || 'N/A',
                    TIPO: payment?.DadosBancarios?.type || 'N/A',
                    'CPF/ CNPJ': payment?.DadosBancarios?.cpf || 'N/A',
                    CHAVE: payment?.DadosBancarios?.pix || 'N/A',
                    VALOR: payment?.Pagar.toLocaleString(),
                    INSTAGRAM: payment?.username || 'N/A'
                });
            }
            return acc;
        }, {});
    
        if (Object.keys(paymentsByResponsavel).length === 0) {
            setLoadingExtract(false);
            Toastr("error", "Nenhum pagamento com nota emitida foi encontrado.");
            return;
        }
    
        const workbook = XLSX.utils.book_new();
    
        const centerAlignment = { alignment: { horizontal: "center", vertical: "center" } };
    
        Object.keys(paymentsByResponsavel).forEach(responsavel => {
            const worksheetData = paymentsByResponsavel[responsavel];
    
            const header = [
                { Nome: 'Nome', 'CPF/ CNPJ': 'CPF/ CNPJ', TIPO: 'TIPO', CHAVE: 'CHAVE', VALOR: 'VALOR', INSTAGRAM: 'INSTAGRAM' }
            ];
    
            const worksheetDataWithHeader = [...header, ...worksheetData];
    
            const worksheet = XLSX.utils.json_to_sheet(worksheetDataWithHeader, { skipHeader: true });
    
            const range = XLSX.utils.decode_range(worksheet['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
                if (!worksheet[cell_address]) continue;
                worksheet[cell_address].s = {
                    font: { bold: true },
                    alignment: { horizontal: "center", vertical: "center" }
                };
            }
    
            Object.keys(worksheet).forEach(cell => {
                if (cell[0] === '!') return;
                worksheet[cell].s = centerAlignment;
            });
    
            XLSX.utils.book_append_sheet(workbook, worksheet, responsavel);
        });
    
        XLSX.writeFile(workbook, 'pagamentos_influencers.xlsx');
    
        setLoadingExtract(false);
        Toastr("success", "Exportação realizada com sucesso!");
    };

    
    
    const generatepayments = async () => {
        await api.get('/influencers/requestpayments/all').then((response) => {
            Toastr("success", "Solicitações realizadas com sucesso!");
            setLoadingRequests(false);
        }).catch((error) => {
            Toastr("error", "Ocorreu um erro!");
            setLoadingRequests(false);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Influencers" breadcrumbItem="Pagamentos da semana" />
                    <Row>
                        <Col lg={12}>
                            <Card className="collaborator-filter">
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">
                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        id="searchCollaborator"
                                                        autoComplete="off"
                                                        placeholder="Buscar Influenciador"
                                                        onChange={(e) => handleSearchCollaborator(e.target.value)}
                                                        value={searchValue}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xxl={2} lg={3}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleSectorChange}
                                                        options={colaboradoresGrupo.map(colaborador => ({
                                                            value: colaborador,
                                                            label: colaborador
                                                        }))}
                                                        value={selectedOption}
                                                        placeholder="Filtrar por responsável"
                                                        isClearable
                                                    />
                                                </div>
                                            </Col>

                                            <Col xxl={3} lg={3}>
                                                <div className="position-relative">
                                                    <Button color='primary' onClick={() => fetchPayments()} disabled={!isMonday}>
                                                        {loadingRequests ? "Aguarde..." : "Solicitar pagamentos"}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col xxl={3} lg={3}>
                                                <div className="position-relative">
                                                    <Button color='primary' onClick={() => extractPayments()} disabled={!isExtract}>
                                                        {loadingExtract ? "Aguarde..." : "Exportar pagamentos"}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col xxl={3} lg={3} className="d-flex align-items-center">
                                                <div className="position-relative">
                                                    <h5 className="mb-0">
                                                        TOTAL: {!totalPayments ? "Calculando..." : `${totalPayments.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                                                    </h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isLoading ? <Spinners setLoading={setLoading} /> : <List listData={listPayments} />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorsList;
