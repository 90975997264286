import React, { useEffect, useState, useMemo, useContext } from "react"

import Loading from "../../../components/Ui/LoadingCircle"

import { Container, Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"

import ApexRevenue from "./ApexRevenue"

// Importar Breadcrumbs (Trilha de navegação)
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Importar Componentes
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"
import MiniCards from "./mini-card"

import { AuthContext } from "contexts/auth"
import api from "config/api"

import { Fechados, FechadosCards, Title } from "./styles"

const DashboardSaas = props => {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [grafico1, setGrafico1] = useState({
    total: 0,
    ativos: 0,
    arquivados: 0,
  })
  const [grafico2, setGrafico2] = useState({
    atribuidosHoje: 0,
    atribuidosMes: 0,
    atribuidosSemana: 0,
  })
  const [grafico3, setGrafico3] = useState({})
  const [grafico4, setGrafico4] = useState({})
  const [grafico2024, setGrafico2024] = useState({});
  const [anoSelecionado, setAnoSelecionado] = useState(2025);

  const calcularSomaGrafico = (grafico) => {
    return (
      (grafico?.jan || 0) +
      (grafico?.fev || 0) +
      (grafico?.mar || 0) +
      (grafico?.abr || 0) +
      (grafico?.mai || 0) +
      (grafico?.jun || 0) +
      (grafico?.jul || 0) +
      (grafico?.ago || 0) +
      (grafico?.set || 0) +
      (grafico?.out || 0) +
      (grafico?.nov || 0) +
      (grafico?.dez || 0)
    );
  };

  const soma2025 = calcularSomaGrafico(grafico3);
  const soma2024 = calcularSomaGrafico(grafico2024);
  
  const dadosGrafico =
  anoSelecionado === 2025
    ? [
        grafico3?.jan || 0,
        grafico3?.fev || 0,
        grafico3?.mar || 0,
        grafico3?.abr || 0,
        grafico3?.mai || 0,
        grafico3?.jun || 0,
        grafico3?.jul || 0,
        grafico3?.ago || 0,
        grafico3?.set || 0,
        grafico3?.out || 0,
        grafico3?.nov || 0,
        grafico3?.dez || 0,
      ]
    : [
        grafico2024?.jan || 0,
        grafico2024?.fev || 0,
        grafico2024?.mar || 0,
        grafico2024?.abr || 0,
        grafico2024?.mai || 0,
        grafico2024?.jun || 0,
        grafico2024?.jul || 0,
        grafico2024?.ago || 0,
        grafico2024?.set || 0,
        grafico2024?.out || 0,
        grafico2024?.nov || 0,
        grafico2024?.dez || 0,
      ];

  useEffect(() => {
    setLoading(true)
    if (user.token && user.userId) {
      api
        .get(`api/graphics/${user.userId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          const data = response.data
          setGrafico1(data.graphic1)
          setGrafico2(data.graphic2)
          setGrafico3(data.graphic3)
          setGrafico4(data.graphic4)
          setGrafico2024(data.graphic2024)

          setLoading(false)
        })
        .catch(error => {
          console.error("Erro ao carregar os Dados:", error)
          setLoading(false)
        })
    }
  }, [user.token, user.userId])

  document.title = "Dashboard | Plataforma Viraw ©"
  return (
    <React.Fragment>
      {!loading ? (
        <div className="page-content">
          <Container fluid>
            {/* Renderizar Trilha de navegação */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Painel" />

            {/* Cartão do Usuário */}
            <CardUser
              total={grafico1.total}
              ativos={grafico1.ativos}
              archiveds={grafico1.arquivados}
            />

            <Row>
              {/* Cartão de Boas-Vindas */}
              <CardWelcome />

              <Col xl="8">
                <Row>
                  <Fechados>
                    <Title>Influencers Fechados</Title>
                    <FechadosCards>
                      <MiniCards
                        title={"Hoje"}
                        text={`${grafico2.atribuidosHoje}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 1}
                      />
                      <MiniCards
                        title={"Na semana"}
                        text={`${grafico2.atribuidosSemana}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 2}
                      />
                      <MiniCards
                        title={"No mês"}
                        text={`${grafico2.atribuidosMes}`}
                        iconClass={"bx-check-circle"}
                        key={"_card_" + 3}
                      />
                    </FechadosCards>
                  </Fechados>
                </Row>
              </Col>
              <Row>
                <Col md={6}>
              <Card>
                <CardBody>
                <div className="title-and-select">
                    <CardTitle className="card-title">
                      Evolução de Influenciadores Fechados
                    </CardTitle>

                    <Input
                      className="year-select"
                      type="select"
                      value={anoSelecionado}
                      onChange={(e) => setAnoSelecionado(Number(e.target.value))}
                    >
                      <option value={2024}>2024</option>
                      <option value={2025}>2025</option>
                    </Input>
                    </div>
                  <div id="revenue-chart">
                    <ApexRevenue
                      dataColors='["--bs-primary"]'
                      data={dadosGrafico}
                      anoSelecionado={anoSelecionado}
                    />
                  </div>
                  {anoSelecionado === 2025 ? (
                  <text className="card-title">
                      Total de Influencers Fechados: {soma2025.toLocaleString()}
                    </text>
                  ) : (
                    <text className="card-title">
                      Total de Influencers Fechados: {soma2024.toLocaleString()}
                    </text>
                  )}
                </CardBody>
              </Card>

                </Col>
                <Col md={12} xl="5">
                  <SalesAnalytics
                    dataColors='["--bs-warning", "--bs-info", "--bs-success"]'
                    dashboard={true}
                    values={[
                      grafico4 && grafico4.postsHoje,
                      grafico4 && grafico4.postsSemana,
                      grafico4 && grafico4.postsMes,
                    ]}
                  />
                </Col>
              </Row>
            </Row>
          </Container>
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default DashboardSaas
