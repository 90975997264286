import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import Loading from "components/Ui/LoadingCircle"
import { Link } from "react-router-dom"

const List = ({ listData }) => {
  const [influencer, setInfluencer] = useState()
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState("Todas")

  const formatCurrency = value => {
    if (value == null) {
      return ""
    }
    return parseFloat(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    })
  }

  function handlePutIsPaymentSuccess(id, isNote) {
    profileMeta(id, isNote).then(() => {
      Toastr("success", "Sucesso!")

      setTimeout(() => {
        window.location.reload()
      }, 3000)
    })
  }

  const handleModal = () => {
    setShowModal(!showModal)
    if (!showModal) {
      setInfluencer(null)
      setSelectedCampaign("Todas")
    }
  }

  const handleModalOpen = async selectedInfluencer => {
    setInfluencer(selectedInfluencer)
    await new Promise(resolve => setTimeout(resolve, 0))
    setShowModal(true)
  }

  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Maria Eduarda":
        return "badge badge-soft-success"
      case "Jadson Nogueira":
        return "badge badge-soft-info"
      case "Karol":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const sectorBadgeCampaigns = option => {
    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info"
      case "SuperBet e XpBet":
        return "badge badge-soft-success"
      case "Xp Bet":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const getFilteredHistoric = () => {
    const historicSuperBet = (influencer?.historic || []).map(item => ({
      ...item,
      origem: "SuperBet",
    }))

    const historicXpBet = (influencer?.historicXP || []).map(item => ({
      ...item,
      origem: "Xp Bet",
    }))

    if (selectedCampaign === "SuperBet") {
      return historicSuperBet
    } else if (selectedCampaign === "Xp Bet") {
      return historicXpBet
    } else {
      return [...historicSuperBet, ...historicXpBet]
    }
  }

  const sortedListData = [...listData].sort((a, b) => {
    const saldoA = a?.balanco?.saldo || 0
    const saldoB = b?.balanco?.saldo || 0
    return saldoB - saldoA
  })

  return (
    <React.Fragment>
      <Row>
        {sortedListData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.influId
                          ? sectorBadgeClasses(influencer?.responsavel)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.influId
                        ? influencer?.responsavel
                        : "Nome indisponível"}
                    </span>

                    <span
                      style={{ marginLeft: "10px" }}
                      className={
                        influencer && influencer.campaign
                          ? sectorBadgeCampaigns(influencer.campaign)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer ? influencer.campaign : "Nome indisponível"}
                    </span>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.photo}
                    alt="Logo da viraw"
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer?.influencer}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer?.username}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    {influencer?.balanco?.saldo?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })}
                  </p>
                </div>

                <Row>
                  <Col md="6">
                    <Button
                      style={{ width: "100%" }}
                      className="btn btn-soft-primary d-block"
                      onClick={() => handleModalOpen(influencer)}
                    >
                      Detalhes
                    </Button>
                  </Col>
                  <Col md="6">
                    <Link to={`/influencer/${influencer?.plugchat}`}>
                      <Button
                        style={{ width: "100%" }}
                        className="btn btn-soft-primary d-block"
                      >
                        Perfil
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal isOpen={showModal} toggle={handleModal}>
        <ModalHeader toggle={handleModal}>Detalhes</ModalHeader>

        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <h3>{influencer?.influencer}</h3>

            <a
              className="mb-0 text-muted font-size-12"
              href={`https://instagram.com/${influencer?.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              @{influencer?.username}
            </a>
          </div>
          <hr />
          <h4>Histórico</h4>

          <div className="mb-3">
            <label htmlFor="campaignFilter">Filtrar por Campanha:</label>
            <Input
              type="select"
              id="campaignFilter"
              value={selectedCampaign}
              onChange={e => setSelectedCampaign(e.target.value)}
            >
              <option value="Todas">Todas</option>
              <option value="SuperBet">SuperBet</option>
              <option value="Xp Bet">Xp Bet</option>
            </Input>
          </div>

          {getFilteredHistoric().length > 0 ? (
            getFilteredHistoric()
              .sort((a, b) => new Date(a.Inicio) - new Date(b.Inicio))
              .map((item, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <h6>
                    <b>Campanha:</b> {item.origem}
                  </h6>
                  <h6>
                    <b>Período:</b> {item.Inicio} - {item.Final}
                  </h6>
                  {item.origem === "SuperBet" && (
                    <h6>
                      <b>FTDs:</b> {item.FTDs}
                    </h6>
                  )}
                  {item.origem === "Xp Bet" && (
                    <h6>
                      <b>CPA:</b> {item.CPA}
                    </h6>
                  )}
                  <h6>
                    <b>Valor:</b>{" "}
                    {item.Valor.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h6>
                  <h6>
                    <b>Ganho:</b>{" "}
                    {item.Ganho.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h6>
                  <hr />
                </div>
              ))
          ) : (
            <p>Nenhum dado para esta campanha.</p>
          )}

          <Row>
            <Col md={3}>
              <h5>
                <b>FTDs:</b>{" "}
                {influencer?.historic?.reduce(
                  (total, item) => total + (item.FTDs || 0),
                  0
                ) ?? 0}
              </h5>
              <h5>
                <b>CPA:</b>{" "}
                {influencer?.historicXP?.reduce(
                  (total, item) => total + (item.CPA || 0),
                  0
                ) ?? 0}
              </h5>
            </Col>
            <Col md={4}>
              <h5>
                <b>Total Ganho:</b>{" "}
                {(influencer?.balanco?.ganho || 0).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                })}
              </h5>
            </Col>
            <Col md={5}>
              <h5>
                <b>Total Retirado:</b>{" "}
                {(influencer?.balanco?.retirado || 0).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                })}
              </h5>
            </Col>
            <hr />
            <Col>
              <h4>
                <b>Saldo:</b>{" "}
                {(influencer?.balanco?.saldo || 0).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                })}
              </h4>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default List
