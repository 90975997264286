import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

//import images
import avatar from "../../../assets/users/avatar-1.jpg";
import { AuthContext } from 'contexts/auth';

const Section = () => {
    const { user } = useContext(AuthContext)

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col lg={12}>
                    <div className="d-flex align-items-center">
                        <img src={user.photo} alt="" className="avatar-sm rounded" style={{marginTop: '50px'}}/>
                        <div className="ms-3 flex-grow-1">
                            <h5 className="mb-1 card-title" style={{ marginLeft: '-20px'}}>Olá, {user.fullname}</h5>
                            <p className="text-muted mb-0">Seja Bem-Vindo ao Dashboard XP Bet!</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Section;