import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import Loading from "components/Ui/LoadingCircle";

const SalesAnalytics = ({ data, dataColors, dataDias }) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("Geral");

  const sumMonthlyData = (dataDias) => {
    const monthlyData = {};
    dataDias.forEach(item => {
      if (!item.Period) {
        console.error("item.Period é undefined ou null:", item);
        return; 
      }

      const [year, month] = item.Period.split('-').slice(0, 2);
      const key = `${year}-${month}`;

      if (!monthlyData[key]) {
        monthlyData[key] = {
          Clicks: 0,
          Registrations: 0,
          FirstDepositCount: 0
        };
      }

      monthlyData[key].Clicks += item.Clicks || 0;
      monthlyData[key].Registrations += item.Registrations || 0;
      monthlyData[key].FirstDepositCount += item.FirstDepositCount || 0;
    });

    return monthlyData;
  };

  // Calcular os dados mensais
  const monthlyData = sumMonthlyData(dataDias);

  // Filtrar dados para o mês selecionado
  const getMonthData = (monthKey) => {
    return monthlyData[monthKey] || {
      Clicks: 0,
      Registrations: 0,
      FirstDepositCount: 0
    };
  };

  const filteredData = selectedMonth === "Geral"
    ? {
        Clicks: Object.values(monthlyData).reduce((acc, curr) => acc + curr.Clicks, 0),
        Registrations: Object.values(monthlyData).reduce((acc, curr) => acc + curr.Registrations, 0),
        FirstDepositCount: Object.values(monthlyData).reduce((acc, curr) => acc + curr.FirstDepositCount, 0),
      }
    : getMonthData(selectedMonth);

  const options = {
    labels: ["Clicks", "Registros", "FTD's"],
    colors: apexsalesAnalyticsChartColors,
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
  };

  const monthNames = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", 
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  const getMonthName = (monthKey) => {
    const [year, month] = monthKey.split('-');
    const monthIndex = parseInt(month) - 1;
    if (monthIndex >= 0 && monthIndex < 12) {
      return `${monthNames[monthIndex]} de ${year}`;
    } else {
      return "Mês inválido";
    }
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card>
          <CardBody>
            <Form>
              <FormGroup>
                <Label for="monthSelect">Selecione o mês: </Label>
                <Input type="select" name="month" id="monthSelect" value={selectedMonth} onChange={handleMonthChange}>
                  <option value="Geral">Total</option>
                  {Object.keys(monthlyData).map(key => (
                    <option key={key} value={key}>{getMonthName(key)}</option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
            {filteredData ? (
              <div>
                <div id="donut-chart">
                  <ReactApexChart
                    options={options}
                    series={[filteredData.Clicks, filteredData.Registrations, filteredData.FirstDepositCount]}
                    type="donut"
                    height={340}
                    className="apex-charts"
                  />
                </div>
                <div className="text-center text-muted">
                  <Row>
                
                    <Col xs="4">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-info me-1" />Clicks:
                        </p>
                        <h6>{filteredData.Clicks.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                    <Col xs="4">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-success me-1" />Registros
                        </p>
                        <h6>{filteredData.Registrations.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                    <Col xs="4">
                      <div className="mt-4">
                        <p className="mb-2 text-truncate text-dark">
                          <i className="mdi mdi-circle text-danger me-1" />FTD's
                        </p>
                        <h6>{filteredData.FirstDepositCount.toLocaleString('pt-BR')}</h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <p>Nenhum dado disponível para o mês selecionado.</p>
            )}
          </CardBody>
        </Card>
      </Col>
      {loading && <Loading />}
    </React.Fragment>
  );
};

export default SalesAnalytics;
