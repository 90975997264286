import React, { useState, useEffect, useContext } from "react"
import Toasr from "../../Ui/Notifications"
import api from "config/api"
import { AuthContext } from "../../../contexts/auth"
import { StateContext } from "contexts/states"
import { formatCPF } from "utils/formatCpf"
import { sortearComMenorPeso } from "utils/sortUserGrupos"
import { onSubmitInfluencersEdit, FecharInfluencer, } from "../../../pages/Captacao/Contacts/ContactList/requests"
import InputMask from 'react-input-mask';
import './styles.css';
import styles from './styles'; // Caminho para o seu arquivo styles.js


import { formatAleatoria, formatCnpj, formatCpf, formatPhoneNumber, validateEmail } from "../../../utils/masks"
import { Row, Col, Label, Input, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Button } from "reactstrap"
import { useFormik} from "formik"
import * as Yup from "yup"

const ModalUpdatedInfluencer = (props) => {
    const { user } = useContext(AuthContext)
    const { modalEditNew, setModalEditNew } = useContext(StateContext)
    const [profileData, setProfileData] = useState()
    const [profileDataAvatar, setProfileDataAvatar] = useState()
    const [contact, setContact] = useState()
    const [statusList, setStatusList] = useState()
    const [statusClose, setStatusClose] = useState()
    const [selectedStatus, setSelectedStatus] = useState("")
  const [showCustomJustificationInput, setShowCustomJustificationInput] = useState(false);
  const [customJustification, setCustomJustification] = useState("");
  const [customJustificationText, setCustomJustificationText] = useState(""); 
    
    const type_chave = ["CNPJ", "CPF", "Telefone", "E-mail", "Aleatória"]
    const [nome, setNome] = useState("")
    const [cpf, setCpf] = useState("")
    const [value, setValue] = useState("")
  const [tipoChavePix, setTipoChavePix] = useState("")
  const [chavePix, setChavePix] = useState("")
  const [validEmail, setValidEmail] = useState(true)
  
  const [fechadoModal, setFechadoModal] = useState(false)
  const [confirmarModal, setConfirmarModal] = useState(false)
  const [confirmarEditModal, setConfirmarEditModal] = useState(false)
  
  const [selectedToastType, setSelectedToastType] = useState(); // Inicialize sem valor padrão
  const [link_plug_chat, setLink_plug_chat] = useState("")
  const [dataFechamento, setDataFechamento] = useState("")
  const [horaFechamento, setHoraFechamento] = useState("")
  const [userGrupos, setUserGrupos] = useState([])
  
  const handleToastTypeChange = (event) => {
      setSelectedToastType(event.target.value);
    };
    
  useEffect(() => {
      async function getUserGrupos() {
          await api
          .get("/users/api/3", {
              headers: {
                  Authorization: `Bearer ${user.token}`,
                },
            })
            .then(response => {
                setUserGrupos(response.data)
            })
            .catch(error => {
                console.error(error)
            })
        }
        getUserGrupos()
    }, [])
    
    useEffect(() => {
        setContact(props.contact);
        setProfileData(props.profileData);
        setProfileDataAvatar(props.profileDataAvatar);
     }, [props.contact, props.profileData, props.profileDataAvatar]);
     
    
    useEffect(() => {
        async function getStatus() {
            await api
            .get("/status", {
          headers: {
            Authorization: `Bearer ${user.token}`,
        },
    })
    .then(response => {
        let status = [];
        response.data.forEach(element => {
            if (element.id === 5) {
                return;
            }
            if (element.status === "Fechado") {
                setStatusClose(element.id);
            } else {
                status.push(element);
            }
        });
        setStatusList(status);
    })
    .catch(error => {
        console.error(error);
    });
}
getStatus();
}, []);


const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
        phone: (props.contact && props.contact.phone) || "",
        email: (props.contact && props.contact.email) || "",
        fullname: (props.contact && props.contact.fullname) || "",
        username: (props.contact && props.contact.username) || "",
        following: (profileData && profileData.following) || "",
        followers: (profileData && profileData.followers) || "",
        status: (props.contact && props.contact.status) || "",
    },
    validationSchema: Yup.object({
        phone: Yup.string().notRequired("Por favor, informe seu telefone"),
      email: Yup.string()
      .email("Por favor, informe um e-mail válido")
      .notRequired("Por favor, informe seu e-mail"),
      fullname: Yup.string().required("Por favor, informe o nome completo"),
      username: Yup.string().required("Por favor, informe o nome de usuário"),
      following: Yup.string().required(
          "Por favor, informe a contagem de seguidores"
        ),
        followers: Yup.string().required(
            "Por favor, informe a contagem de seguidos"
        ),
        status: Yup.string().required("Por favor, selecione o status"),
    }),
    
    onSubmit: values => {
        
        setSelectedStatus(values.status)
        
        onSubmitInfluencersEdit(
            user.token,
            contact.id,
            values.phone,
            values.email,
            values.fullname,
            profileDataAvatar,
            values.username,
            profileData.following,
            profileData.followers,
            values.status,
            props.Updated,
            selectedToastType,
            customJustification === "Outro" ? customJustificationText : customJustification
        )
    },
})

  const toggleEdit = () => {
    console.log("Fechando modal")
    setModalEditNew(!modalEditNew)
  }
  const handleConfirmarSubmit = () => {
    toggleConfirmarModal()
  }

  const toggleFechadoModal = () => {

    setNome(""),
      setCpf(""),
      setTipoChavePix(""),
      setChavePix(""),
      setValue(""),
      setLink_plug_chat(""),
      setDataFechamento(""),
      setHoraFechamento("")
    setFechadoModal(!fechadoModal)

  }

  const onChangePix = (event) => {

    if (tipoChavePix === "CPF") {
      const formattedValue = formatCpf(event);
      setChavePix(formattedValue)
    } else if (tipoChavePix === "CNPJ") {
      const formattedValue = formatCnpj(event);
      setChavePix(formattedValue)
    } else if (tipoChavePix === "Telefone") {
      const formattedValue = formatPhoneNumber(event);
      setChavePix(formattedValue)
    } else if (tipoChavePix === "E-mail") {
      setChavePix(event)
      setValidEmail(validateEmail(event));
    } else if (tipoChavePix === "Aleatória") {
      const formattedValue = formatAleatoria(event);
      setChavePix(formattedValue)
    }

    else {
      setChavePix(event)
    }
  }

  const handleConfirmClosing = () => {
    const sort = sortearComMenorPeso(userGrupos)

    if (nome === "", cpf === "", tipoChavePix === "", chavePix === "", link_plug_chat === "", dataFechamento === "", horaFechamento === "") {
      Toasr('warning', "Preencha todos os campos obrigatórios!")
    } else {
      FecharInfluencer(
        contact.id,
        user.token,
        statusClose,
        nome,
        cpf,
        tipoChavePix,
        chavePix, value,
        link_plug_chat,
        sort.id,
        dataFechamento,
        horaFechamento,
        confirm,
        toogle
      )

      handleConfirmarAcao()
    }


  }

  const confirm = () => {

  }
  const toogle = () => {

  }

  const handleTipoChavePixChange = event => {
    const valorSelecionado = event.target.value
    setTipoChavePix(valorSelecionado)
    setChavePix("")
  }

  const handleConfirmarAcao = () => {
    toggleConfirmarModal()
    toggleFechadoModal()
  }

  const toggleConfirmarModal = () => {
    setConfirmarModal(!confirmarModal)
  }
  const toggleConfirmarEditModal = () => {
    setConfirmarEditModal(!confirmarEditModal)
  }

  const handleConfirmarEdicao = () => {
    toggleConfirmarEditModal()
  }


  return (
    <Modal isOpen={modalEditNew} toggle={toggleEdit}>
      <ModalHeader toggle={toggleEdit} tag="h4" className="custom-modal-header">
        Editar Influenciador
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={3} className="d-flex align-items-center mb-3">
          {profileDataAvatar ? (
            <img
            style={{ borderRadius: "50px", width: "100px" }}
            src={props.profileDataAvatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg";
            }}
            />
          ) : null}
            </Col>

            <Col xs={9}>
              <div className="d-flex mt-2">
                <i className="bx bxl-instagram" style={styles.instagramIcon}></i>
                <Label className="custom-text-username2">
                  {validation.values.username || ""}
                </Label>
              </div>

              <Row>
                <Col xs={8}>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <i className="bx bx-user" style={styles.icon}></i>
                      <Label className="custom-text-padrao">
                      {profileData && !isNaN(Number(profileData.followers)) 
                      ? Number(profileData.followers).toLocaleString() 
                      : ""} Seguidores
                      </Label>
                    </div>

                    <div className="d-flex align-items-center">
                      <i className="bx bx-user" style={styles.icon}></i>
                      <Label className="custom-text-padrao">
                      {profileData && !isNaN(Number(profileData.following)) 
                      ? Number(profileData.following).toLocaleString()
                      : ""} Seguindo
                      </Label>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>


          <Row>
            <Col xs={6}>
            <div className="mb-3">
                <Label className="custom-text-geral">Nome Completo</Label>
                <Input
                  name="fullname" 
                  type="text"
                  className="custom-text-geral"
                  placeholder="Digite o nome completo"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fullname || ""}
                  invalid={
                    validation.touched.fullname &&
                      validation.errors.fullname
                      ? true
                      : false
                  }
                />
                {validation.touched.fullname &&
                  validation.errors.fullname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fullname}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="custom-text-geral">Telefone</Label>
                <InputMask
                style={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '5px',
                  borderColor: '#d6d6d6',
                  border: '1px solid #d6d6d6',
                  paddingLeft: '5px',
                }}
                  className="custom-text-geral"
                  name="phone"
                  type="text"
                  mask="(99) 9 9999-9999"
                  maskChar=""
                  placeholder="Digite o telefone"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>

            </Col>
            <Col xs={6}>
            <div className="mb-3">
                <Label className="custom-text-geral">Email</Label>
                <Input
                  name="email"
                  type="email"
                  placeholder="Digite o Email"
                  className="custom-text-geral"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="custom-text-geral">Status</Label>
                <Input
                  type="select"
                  name="status"
                  className="custom-text-geral"
                  onChange={(e) => {
                    validation.handleChange(e);
                    const selectedValue = e.target.value;
                    if (selectedValue === "3") {
                      setShowCustomJustificationInput(true);
                    } else {
                      setShowCustomJustificationInput(false);
                    }
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.status || ""}
                  invalid={
                    validation.touched.status && validation.errors.status
                      ? true
                      : false
                  }
                >
                  <option value="">Selecione um status</option>
                  {statusList &&
                    statusList.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.status}
                      </option>
                    ))}
                </Input>

                {showCustomJustificationInput && (
                  <FormGroup>
                    <br />
                    <Label for="customJustificationSelect" className="custom-text-geral">Selecione um motivo:</Label>
                    <Input
                      type="select"
                      className="custom-text-geral"
                      name="customJustification"
                      id="customJustificationSelect"
                      onChange={(e) => setCustomJustification(e.target.value)}
                      value={customJustification}
                    >
                      <option value="" disabled hidden>
                        Selecione um motivo...
                      </option>
                      <option value="Valores.">Valores.</option>
                      <option value="Não divulga casas de aposta.">Não divulga casas de aposta.</option>
                      <option value="Não respondeu.">Não respondeu.</option>
                      <option value="Outro">Outro...</option>
                    </Input>
                    {customJustification === "Outro" && (
                      <div className="mt-3">
                        <Label for="customJustificationText">Motivo personalizado:</Label>
                        <Input
                          type="text"
                          name="customJustificationText"
                          id="customJustificationText"
                          placeholder="Digite o motivo personalizado aqui..."
                          onChange={(e) => setCustomJustificationText(e.target.value)}
                          value={customJustificationText}
                        />
                      </div>
                    )}
                  </FormGroup>
                )}

                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>
              
            </Col>
            <Col xs={12}>
            </Col>
          </Row>

          
          <Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="custom-text-geral">De onde veio o influenciador?</Label>

                  <Row>
                    <Col xs={6}>
                  <div className="form-check mb-2">
                    <input
                      type="radio"
                      id="radio1"
                      name="toastType"
                      className="form-check-input"
                      value="Instagram"
                      onChange={(e) => setSelectedToastType(e.target.value)}
                    />
                    <Label className="custom-text-geral" htmlFor="radio1">
                      Instagram
                    </Label>
                  </div>

                  <div className="form-check mb-2">
                    <input
                      type="radio"
                      id="radio2"
                      name="toastType"
                      className="form-check-input"
                      value="WhatsApp"
                      onChange={(e) => setSelectedToastType(e.target.value)}
                    />
                    <Label className="custom-text-geral" htmlFor="radio2">
                      WhatsApp
                    </Label>
                  </div>
                  </Col>
                  
                  <Col xs={6}>
                  <div className="form-check mb-2">
                    <input
                      type="radio"
                      id="radio3"
                      name="toastType"
                      className="form-check-input"
                      value="Youtube"
                      onChange={handleToastTypeChange}
                    />
                    <Label className="custom-text-geral" htmlFor="radio3">
                      YouTube
                    </Label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      type="radio"
                      id="radio4"
                      name="toastType"
                      className="form-check-input"
                      value="Forms"
                      onChange={handleToastTypeChange}
                    />
                    <Label className="custom-text-geral" htmlFor="radio4">
                      Forms Google
                    </Label>
                  </div>
                  </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="button-others"
                  onClick={toggleFechadoModal}
                >
                  Fechar Influenciador
                </button>
                <button
                  type="submit"
                  className="button-confirmar"
                >
                  Salvar
                </button>


                <Modal isOpen={fechadoModal} toggle={toggleFechadoModal}>
                  <ModalHeader toggle={toggleFechadoModal} tag="h4">
                    Detalhes do Fechamento
                  </ModalHeader>
                  <ModalBody>
                    <h5>Dados Pagamento</h5>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Nome Completo
                        </Label>
                        <Input
                          type="text"
                          name="nome"
                          placeholder="Digite o nome"
                          value={nome}
                          onChange={e => setNome(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Tipo de Chave Pix
                          </Label>
                          <Input
                            type="select"
                            name="type_pix"
                            className="form-select"
                            onChange={handleTipoChavePixChange}
                            value={tipoChavePix}
                          >
                            <option value="">Selecione</option>
                            {type_chave &&
                              type_chave.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                          </Input>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="mb-3">
                          <Label className="form-label">Chave PIX</Label>
                          <Input
                            type={
                              tipoChavePix === "E-mail" ? "email" : "text"
                            }
                            name="chavePix"
                            placeholder="Digite a chave PIX"
                            value={chavePix}
                            onChange={e => onChangePix(e.target.value)}
                          />
                        </div>
                        {tipoChavePix === "E-mail" && !validEmail && (
                          <h6> Email inválido</h6>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            style={{ marginTop: "10px" }}
                          >
                            CPF
                          </Label>
                          <Input
                            name="cpf"
                            type="text"
                            placeholder="Digite o CPF"
                            onChange={e => setCpf(formatCPF(e.target.value))}
                            onBlur={validation.handleBlur}
                            value={cpf}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            style={{ marginTop: "10px" }}
                          >
                            Valor Semanal
                          </Label>
                          <Input
                            name="value"
                            type="number"
                            placeholder="Digite o valor semanal"
                            onChange={e => setValue(e.target.value)}
                            onBlur={validation.handleBlur}
                            value={value}
                          />
                        </div>
                      </Col>
                    </Row>

                    <hr style={{ backgroundColor: "black" }} />
                    <h5>Dados PlugChat</h5>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginTop: "10px" }}
                      >
                        Link Plug Chat
                      </Label>
                      <Input
                        name="link_plug_chat"
                        type="text"
                        placeholder="Digite o Chat Link"
                        onChange={e => setLink_plug_chat(e.target.value)}
                        onBlur={validation.handleBlur}
                        value={link_plug_chat}
                        invalid={
                          validation.touched.link_plug_chat &&
                            validation.errors.link_plug_chat
                            ? true
                            : false
                        }
                      />
                      {validation.touched.link_plug_chat &&
                        validation.errors.link_plug_chat ? (
                        <FormFeedback type="invalid">
                          {validation.errors.link_plug_chat}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Data de Inicio
                          </Label>
                          <Input
                            type="date"
                            name="dataFechamento"
                            value={dataFechamento}
                            onChange={e =>
                              setDataFechamento(e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Hora de Inicio
                          </Label>
                          <Input
                            type="time"
                            name="hora"
                            value={horaFechamento}
                            onChange={e =>
                              setHoraFechamento(e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>

                  <Modal
                    isOpen={confirmarModal}
                    toggle={toggleConfirmarModal}
                  >
                    <ModalHeader toggle={toggleConfirmarModal} tag="h4">
                      Confirmar Fechamento
                    </ModalHeader>
                    <ModalBody>
                      Você tem certeza de que deseja confirmar esta ação?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary"
                        onClick={toggleConfirmarModal}
                      >
                        Cancelar
                      </Button>
                      <Button
                        color="success"
                        onClick={() => handleConfirmClosing()}
                      >
                        Confirmar
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={toggleFechadoModal}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="success"
                      onClick={handleConfirmarSubmit}
                    >
                      Confirmar
                    </Button>
                  </ModalFooter>
                </Modal>


              </div>
              <Modal
                isOpen={confirmarEditModal}
                toggle={toggleConfirmarEditModal}
              >
                <ModalHeader toggle={toggleConfirmarEditModal} tag="h4">
                  Confirmar Edição
                </ModalHeader>
                <ModalBody>
                  Você tem certeza de que deseja confirmar esta edição?
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={toggleConfirmarEditModal}
                  >
                    Cancelar
                  </Button>
                  <Button color="success" onClick={handleConfirmarEdicao}>
                    Confirmar
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdatedInfluencer