import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink } from 'reactstrap';
import { StatisticsApplicationsChart } from './JobCharts';

const StatisticsApplications = ({Deposits, FTDs, Rev, CPA, Commission, data}) => {
    const [duration, setDuration] = useState('ano');
    const [statisticData, setStatisticData] = useState(null);

const dataValues = {
    Deposits: Deposits,
    FTDs: FTDs,
    CPA: CPA,
    Commission: Commission
}
    const generateRandomData = () => {
        const statisticData = {

            mes: {
                companay: [25, 95, 87, 14, 56, 12, 14, 56, 35, 14, 25],
                newjobs: [85, 87, 56, 45, 14, 87, 56, 98, 19, 51, 78, 49],
                totaljobs: [85, 75, 95, 48, 59, 68, 15, 35, 26, 45, 59, 57],
                jobview: [78, 89, 84, 98, 67, 59, 48, 42, 35, 29, 18, 59],
                label: ['01/01/2022', '02/02/2022', '03/03/2022', '04/04/2022', '05/05/2022', '06/06/2022', '07/07/2022',
                    '08/08/2022', '09/09/2022', '10/10/2022', '11/11/2022']
            }

        };

        setStatisticData(statisticData);
    };

    useEffect(() => {
        generateRandomData();
    }, []);


    if (!statisticData) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <Col lg={8}>
                <Card>
                    <CardBody>
                        <StatisticsApplicationsChart seriesData={dataValues} dataColors='["--bs-primary", "--bs-success", "--bs-warning", "--bs-info", "--bs-danger", "--bs-danger"]' dir="ltr" />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default StatisticsApplications;
