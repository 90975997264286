import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { StateContext } from 'contexts/states';

const StyledButton = styled.button`
  position: fixed;
  bottom: 50px;
  right: 20px;
  background: linear-gradient(144deg, #536dfe 50%, #00ddeb);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center; 
`;

const PlusSign = styled.div`
  font-size: 1.5em;
`;

const Text = styled.div`
  font-size: 1.2em;
  font-weight: 500;
  margin-left: 5px; 
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 0.3s;
`;

const HoverButton = styled(StyledButton)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);

  &:hover {
    width: 125px;
    border-radius: 10px;
    transition-duration: .3s;

    ${Text} {
      opacity: 1;
    }
  }
`;

const AddButton = ({ onClick }) => {

  return (
    <HoverButton onClick={onClick}>
      <PlusSign>
        <FontAwesomeIcon icon={faPlus} />
      </PlusSign>
      <Text>Cadastrar</Text>
    </HoverButton>
  );
};

export default AddButton;
