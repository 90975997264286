import React, { useState, useContext, useEffect, useRef } from 'react';
import cardStyles from "./cardStyles";
import Toastr from "../../../components/Ui/Notifications"
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import ModalUpdatedInfluencer from "../../../components/Modals/updateInflu2"
import { handleDeleteUser } from "../Contacts/ContactList/requests"
import { StateContext } from 'contexts/states';

const Card = ({ data, ...rest }) => {
  const styles = cardStyles();

  const [showMenu, setShowMenu] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { modalEditNew, setModalEditNew } = useContext(StateContext);
  const { setIdInfluDelete } = useContext(StateContext);
  const { setIdInfluRemarketing } = useContext(StateContext);
  const { deleteInfluModal, setDeleteInfluModal } = useContext(StateContext);
  const { modalRemarketing, setModalRemarketing } = useContext(StateContext)
  const menuRef = useRef(null); 
  const buttonRef = useRef(null);

  const { setContact, setProfileDataAvatar, setProfileData } = useContext(StateContext);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEdit = () => {
    if (data.status !== 8) {
      setContact(data);
      setProfileDataAvatar(data.photo);
      setProfileData({
        followers: data.followers,
        following: data.following,
      });
      setModalEditNew(true);
      setShowMenu(false);
    }
  };

  const handleRemarketing = () => {
    setIdInfluRemarketing(data.id)
    setModalRemarketing(true)
  }

  const getTagForStatusAndTime = () => {
    if (data.created_at) {
      const createdDate = new Date(data.created_at);

      const statusDate = new Date(data.status_date)

      const today = new Date();
      const diffInMs = today - createdDate;
      const statusMs = today - statusDate

      const statusContagem = statusMs / (1000 * 60 * 60); 
      const diffInHours = diffInMs / (1000 * 60 * 60);

      if (data.status === 2 || data.status === 3) {
        if (statusContagem > 72) {
          return { text: "+72h", color: "#FF4500" };
        } else if (statusContagem > 48) {
          return { text: "+48h", color: "#3286d1" };
        } else if (statusContagem > 24) {
          return { text: "+24h", color: "#98c7f1"}
        }
      }

      if (diffInHours >= 24 && data.status === 1) {
        return { text: "Ausente", color: "#2fa9ec" };
      }

      if (diffInHours <= 24 && data.status !== 8) {
        return { text: "Novo", color: "#47e98b" };
      }
    }

    if (data.status_date) {
      const updatedDate = new Date(data.status_date);
      const today = new Date();
      const diffInMs = today - updatedDate;
      const diffInHours = diffInMs / (1000 * 60 * 60);

      if (diffInHours > 1 && data.status != 8 && data.status != 2 && data.status != 4 && data.status != 1 && data.status != 7) {
        return { text: "Em espera", color: "#808080" };
      }
    }

    return null;
  };

  const redirectWhats = (link) => {
    if (link) {
      window.open(link, "_blank");
    } else {
      Toastr("warning","Link da conversa não foi cadastrado para esse influenciador.");
    }
  };

  const timeTag = getTagForStatusAndTime();
  const tagBarStyle = data.status === 4 ? styles.tagBar2 : styles.tagBar;

  const handleDelete = () => {
    if (data.status !== 8) {
      handleDeleteInfluencer(data.id)
    }
    setShowMenu(false)
  }

  const handleDeleteInfluencer = id => {
    setIdInfluDelete(id)
    setDeleteInfluModal(true)
  }

  const campaignColors = {
    "XP Bet": "#fd5c02",
    Betano: "#ff4500",
    SuperBet: "#ea000b",
    "Aposta Ganha": "#32cd32",
    Shopee: "#ff6600", 
  };

  const defaultColor = "#fd5c02"; 
  const campaignName = data?.campaigns_influencer?.name;
  const backgroundColor = campaignName ? campaignColors[campaignName] || defaultColor : defaultColor;

  return (
    <div style={styles.cardWrapper}>
      <div style={styles.topBar}>
        <div style={tagBarStyle}>

        <span
          style={{
          ...styles.tag,
          backgroundColor,
          color: "white",
          fontWeight: "bold",}}
          >
          {campaignName || "XP Bet"}
        </span>


          {rest.tags?.map((tag, index) => (
            <span key={index} style={styles.tag}>
              {tag}
            </span>
          ))}

          {timeTag && (
            <span
              style={{
                ...styles.tag,
                backgroundColor: timeTag.color,
              }}
              onClick={() => handleRemarketing(data.id)}
            >
              {timeTag.text}
            </span>
          )}

          {data.status != 8 && data.influencer_meta?.[0]?.valueMin && (
            <span style={{ ...styles.tag, backgroundColor: "#FFD700" }}
            onClick={() => Toastr("warning", `O último valor negociado com @${data.username} foi ${data.influencer_meta?.[0]?.valueMin}`)}
            >
              {data.influencer_meta?.[0]?.valueMin}
            </span>
          )}

          {data.status === 8 && data.plug_chat && (
            <span style={{ ...styles.tag, backgroundColor: "#88b8a9" }}>
              {data.plug_chat?.responsavel_rent?.fullname}
            </span>
          )}
        </div>

        <div style={styles.options}>
          {data.status === 4 && (
            <div style={styles.optionsSquare2} onClick={() => redirectWhats(data.linkConversa)}>
              <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" style={{ width: "18px", height: "18px" }}/>
            </div>
          )}

          <div ref={buttonRef} style={styles.optionsSquareMenu} onClick={(event) => { event.stopPropagation(); toggleMenu()}}>⋮</div>

          {showMenu && (
            <div ref={menuRef} style={styles.dropdownMenu}>
              {['edit', 'delete'].map((action) => (
                <div key={action} style={ hoveredItem === action ? { ...styles.dropdownMenuItem, ...styles.dropdownMenuItemHover } : styles.dropdownMenuItem}
                  onMouseEnter={() => setHoveredItem(action)}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={action === 'edit' ? handleEdit : handleDelete}
                >
                  {action === 'edit' ? <FaEdit /> : <FaTrashAlt />}{' '}
                  {action === 'edit' ? 'Editar' : 'Excluir'}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div style={styles.card}>
      <img  src={data.photo} alt={data.photo} style={styles.image} onError={(e) => e.target.src = "https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"}/>
        <div style={styles.info}>
          <h3 style={styles.name}>{data.fullname}</h3>
          <div style={styles.usernameWrapper}>
            <i className="bx bxl-instagram" style={styles.icon} onClick={() => window.open(`https://www.instagram.com/${data.username}`, "_blank")}></i>
            <p onClick={() => window.open(`https://www.instagram.com/${data.username}`, "_blank")} style={styles.username}>{data.username}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
