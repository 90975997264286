import back1 from '../../../assets/images/bgroundDark.webp';
import back2 from '../../../assets/images/bgroundWhite.webp';

export const laneStyle = (tema) => ({
    backgroundColor: tema === "dark" ? "#32394e" : "#dcdfe4",
    overflowY: "auto",
    maxHeight: "70vh",
    padding: "10px",
    borderRadius: "10px",
    color: tema === "dark" ? "#ffffff" : "#010101",
    minWidth: "310px",
  });
  
  export const boardStyle = (tema) => ({
    backgroundImage: tema === "dark" ? `url(${back1})` : `url(${back2})`,
    backgroundPosition: 'center',
  });