import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import Loading from "components/Ui/LoadingCircle";

const SalesAnalytics2 = ({ dataDias, dataColors }) => {
    const apexsalesAnalyticsChartColors = getChartColorsArray(dataColors);
    const [loading, setLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState("Geral");

    const sumMonthlyData = (dataDias) => {
        const monthlyData = {};
        (dataDias || []).forEach(item => {
            if (!item.Period) return;

            const [year, month] = item.Period.split('-').slice(0, 2);
            const key = `${year}-${month}`;

            if (!monthlyData[key]) {
                monthlyData[key] = {
                    Visitors: 0,
                    Registrations: 0,
                    FTDs: 0,
                    Deposits: 0,
                };
            }

            monthlyData[key].Visitors += item.Visitors || 0;
            monthlyData[key].Registrations += item.Registrations || 0;
            monthlyData[key].FTDs += item.FTDs || 0;
            monthlyData[key].Deposits += item.Deposits || 0;
        });

        return monthlyData;
    };

    const monthlyData = sumMonthlyData(dataDias);

    const getMonthData = (monthKey) => {
        return monthlyData[monthKey] || {
            Visitors: 0,
            Registrations: 0,
            FTDs: 0,
            Deposits: 0,
        };
    };

    const filteredData = selectedMonth === "Geral"
        ? {
            Visitors: Object.values(monthlyData).reduce((acc, curr) => acc + curr.Visitors, 0),
            Registrations: Object.values(monthlyData).reduce((acc, curr) => acc + curr.Registrations, 0),
            FTDs: Object.values(monthlyData).reduce((acc, curr) => acc + curr.FTDs, 0),
            Deposits: Object.values(monthlyData).reduce((acc, curr) => acc + curr.Deposits, 0),
        }
        : getMonthData(selectedMonth);

    const options = {
        labels: ["Visitas", "Registros", "FTD's", "Depósitos"],
        colors: apexsalesAnalyticsChartColors,
        legend: { show: false },
        plotOptions: {
            pie: {
                donut: {
                    size: "80%",
                },
            },
        },
    };

    const monthNames = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const getMonthName = (monthKey) => {
        const [year, month] = monthKey.split('-');
        const monthIndex = parseInt(month) - 1;
        return monthIndex >= 0 && monthIndex < 12 ? `${monthNames[monthIndex]} de ${year}` : "Mês inválido";
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    return (
        <React.Fragment>
            <Col lg={4}>
                <Card>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="monthSelect">Selecione o mês:</Label>
                                <Input type="select" id="monthSelect" value={selectedMonth} onChange={handleMonthChange}>
                                    <option value="Geral">Total</option>
                                    {Object.keys(monthlyData).map(key => (
                                        <option key={key} value={key}>{getMonthName(key)}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Form>
                        {filteredData ? (
                            <div>
                                <ReactApexChart
                                    options={options}
                                    series={[
                                        filteredData.Visitors,
                                        filteredData.Registrations,
                                        filteredData.FTDs,
                                        filteredData.Deposits,
                                    ]}
                                    type="donut"
                                    height={265}
                                    className="apex-charts"
                                />
                                <div className="text-center text-muted">
                                    <Row>
                                    <Row>
                                        <Col xs="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-dark">
                                                    <i className="mdi mdi-circle text-primary me-1" />Visitas
                                                </p>
                                                <h6>{filteredData.Visitors.toLocaleString('pt-BR')}</h6>
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-dark">
                                                    <i className="mdi mdi-circle text-success me-1" />Registros
                                                </p>
                                                <h6>{filteredData.Registrations.toLocaleString('pt-BR')}</h6>
                                            </div>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col xs="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-dark">
                                                    <i className="mdi mdi-circle text-warning me-1" />FTD's
                                                </p>
                                                <h6>{filteredData.FTDs.toLocaleString('pt-BR')}</h6>
                                            </div>
                                        </Col>
                                        <Col xs="6">
                                            <div className="mt-4">
                                                <p className="mb-2 text-dark">
                                                    <i className="mdi mdi-circle text-danger me-1" />Depósitos
                                                </p>
                                                <h6>{filteredData.Deposits.toLocaleString('pt-BR')}</h6>
                                            </div>
                                        </Col>
                                        </Row>
                                    </Row>
                                </div>
                            </div>
                        ) : (
                            <p>Nenhum dado disponível para o mês selecionado.</p>
                        )}
                    </CardBody>
                </Card>
            </Col>
            {loading && <Loading />}
        </React.Fragment>
    );
};

export default SalesAnalytics2;
