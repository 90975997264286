import React, { useEffect, useState, useContext } from "react";
import { laneStyle, boardStyle } from './styles';
import { Col } from "reactstrap";
import { AuthContext } from "contexts/auth";
import Spinners from "../../../components/Common/Spinner"
import Board from "react-trello";
import Card from "./card.js";
import Botao from "./button/button";
import api from "config/api";
import Toastr from "../../../components/Ui/Notifications"
import ModalSemSucesso from "components/Modals/SemSucesso2"
import { StateContext } from "contexts/states"
import ModalUpdatedInfluencer from "../../../components/Modals/updateInflu2"
import Remarketing from "components/Modals/Remarketing"
import "./styles.css";

import ModalZapCaptado from "../../../components/Modals/ChatLinkModal2"
import ModalCriarInfluencer from "components/Modals/new/"
import ModalDeleteInfluencer from "components/Modals/ConfirmDeleteInfluencer"

import FechadoModal from "../../../components/Modals/FechadoModal"
import ModalFechadoPayment from "../../../components/Modals/FechadoPayment2"

const Kanban = () => {
  const { user } = useContext(AuthContext);
  const [lanes, setLanes] = useState([]);
  const [status, setStatus] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [filter, setFilter] = useState("semana");
  const [justification, setJustification] = useState("")
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth().toString());
  const [selectedInfluencerId, setSelectedInfluencerId] = useState(null);
  const [Loading, setLoading] = useState(false)

  const [modalCadastrarInflu, setModalCadastrarInflu] = useState(false)
  const [isModalZapCaptadoOpen, setIsModalZapCaptadoOpen] = useState(false);
  const [isModalSemSucesso, setIsModalSemSucesso] = useState(false)
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [remarketing, setRemarketing] = useState('')
  const { modalEditNew, setModalEditNew } = useContext(StateContext);
  const { deleteInfluModal, setDeleteInfluModal } = useContext(StateContext)
  const { modalRemarketing, setModalRemarketing } = useContext(StateContext)
  const { idInfluRemarketing, setIdInfluRemarketing } = useContext(StateContext)

  const [isModalFechadoOpen, setIsModalFechadoOpen] = useState(false);
  const [fecharModalPayment, setFecharModalPayment] = useState(false);
  const {contact, profileDataAvatar, profileData} = useContext(StateContext);

  const [chatLink, setChatLink] = useState("")
  const { tema, setTema } = useContext(StateContext); 

  useEffect(() => {
    async function getStatus() {
      try {
        const response = await api.get("/status", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const filteredStatus = response.data.filter((status) => status.id !== 9);
        setStatus(filteredStatus);
      } catch (error) {
        console.error("Erro ao obter status:", error);
      }
    }

    getStatus()
  }, [user.token]);

  useEffect(() => {
    fetchInfluencersData();
  }, [user.token]);

  useEffect(() => {
    setTema(getTemaFromStorage());
  })


  useEffect(() => {
    if (status.length > 0 && influencers.length > 0) {
      const laneData = status.map((statusItem) => {
        if (!statusItem.id) {
          console.warn("Status sem ID encontrado:", statusItem);
          return null;
        }
  
        const filteredInfluencers = filterInfluencers(influencers, filter, selectedMonth).filter(
          (inf) => inf.status === statusItem.id
        );
  
        return {
          id: statusItem.id?.toString() || "unknown",
          title: `${statusItem.status} ( ${filteredInfluencers.length} )`,
          cards: filteredInfluencers.map((inf) => ({
            id: inf.id.toString(),
            data: inf,
          })),
        };
      });
  
      setLanes(laneData.filter((lane) => lane !== null));
    }
  }, [status, influencers, filter, selectedMonth]);
  

  const fetchInfluencersData = async () => {
    try {
      const response = await api.get(`/influencers/captador/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
  
      setInfluencers(response.data);
    } catch (error) {
      console.error("Erro ao obter influenciadores:", error);
    }
  };

  const filterInfluencers = (influencers, filter, selectedMonth) => {
    const today = new Date();
    return influencers.filter((inf) => {
      const createdAt = new Date(inf.status_date);
      switch (filter) {
        case "dia":
          return createdAt.toDateString() === today.toDateString();
        case "semana":
          const sevenDaysAgo = new Date(today);
          sevenDaysAgo.setDate(today.getDate() - 6);
          return createdAt >= sevenDaysAgo && createdAt <= today;
          case "mes":
            return (
              createdAt.getMonth() === parseInt(selectedMonth, 10) &&
              createdAt.getFullYear() === today.getFullYear() 
            );
        case "todos":
        default:
          return true;
      }
    });
  };

  const handleCardMove = async (cardId, targetLaneId) => {
    const targetLaneIdInt = parseInt(targetLaneId);
  
    const podeContinuar = regras(cardId, targetLaneIdInt, influencers);
  
    if (!podeContinuar) {
      setInfluencers((prevInfluencers) =>
        prevInfluencers.map((influencer) =>
          influencer.id.toString() === cardId
            ? { ...influencer }
            : influencer
        )
      );
      return;
    }
  
    try {
      setInfluencers((prevInfluencers) =>
        prevInfluencers.map((influencer) =>
          influencer.id.toString() === cardId
            ? { ...influencer, status: targetLaneIdInt }
            : influencer
        )
      );
  
      await api.put(
        `/influencers/update/status`,
        {
          id: cardId,
          status: targetLaneIdInt,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error("Erro ao atualizar status do influenciador:", error);
      Toastr("error", "Erro ao mover o influenciador. Alteração revertida.");
      setInfluencers((prevInfluencers) =>
        prevInfluencers.map((influencer) =>
          influencer.id.toString() === cardId
            ? { ...influencer, status: influencer.status }
            : influencer
        )
      );
    }
  };

  async function postJustification(id, observation) {
    await api
      .put(
        "/influencers/update/observation",
        {
          id: id,
          observation: observation,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(response => {
        Toastr("success", "Justificado com sucesso!")

        updateInfluencers(response.data.id, 7)
        requestStatus("7", selectedInfluencer.influencerId)
      })
      .catch(error => {
        console.error("Erro" + error)
      })
  }

  async function postRemarketing(id, remarketing, remarketing_reason) {
    try {
      await api
        .put(
          "/influencers/update/remarketing",
          {
            id: id,
            remarketing: remarketing,
            remarketing_reason: remarketing_reason,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then(response => {
          Toastr("success", "Remarketing registrado com sucesso!")
          setInfluencers(prevInfluencers =>
            prevInfluencers.map(influencer =>
              influencer.id === id
                ? {
                    ...influencer,
                    remarketing: true,
                    remarketing_reason: remarketing_reason,
                  }
                : influencer
            )
          )
        })
    } catch (error) {
      console.error("Erro" + error)
    }
  }

  const regras = (cardId, targetLaneId, influencers) => {
    const influencer = influencers.find((inf) => inf.id.toString() === cardId);
  
    if (influencer && (influencer.status === 7 || influencer.status === 8)) {
      Toastr("error", "Esse influenciador não pode ser movido.");
      return false;
    }
  
    if (influencer && influencer.status === targetLaneId) {
      return false;
    }
  
    if (targetLaneId === 4) {
      setSelectedInfluencerId(influencer.id);
      setIsModalZapCaptadoOpen(true)
      return false
    }

    if (targetLaneId === 4 && !isModalZapCaptadoOpen) {
      Toastr("warning", "Por favor, informe o link da conversa antes de mover o influenciador.");
      return false;
    }

    if (targetLaneId === 7) {
      setSelectedInfluencerId(influencer.id);
      setIsModalSemSucesso(true);
      return false; 
    }

    if (targetLaneId === 7 && !isModalSuccess) {
      Toastr("warning", "Por favor, preencha o motivo antes de mover o influenciador.");
      return false;
    }

    if (targetLaneId === 8) {
      if (!influencer.phone || influencer.phone.trim() === "") {
        Toastr("warning", "Por favor, preencha o número de telefone do influenciador.");
        return false;
      }
      setSelectedInfluencerId(influencer.id);
      setIsModalFechadoOpen(true);
      return false;
    }

    return true;
  };

  const handleConfirmFechado = () => {
    setIsModalFechadoOpen(false);
    setFecharModalPayment(true);
  };
  

  const handleConfirmFechadoPayment = () => {
    setFecharModalPayment(false);
    setTimeout(() => {
      setInfluencers((prevInfluencers) =>
        prevInfluencers.map((influencer) =>
          influencer.id === selectedInfluencerId
            ? { ...influencer, status: 8 }
            : influencer
        )
      );
  
    }, 500);
  };
  
  const submitJustification = (justification) => {
    postJustification(selectedInfluencerId, justification)
    handleStatusUpdate(7)
  }

  const handleStatusUpdate = (newStatus) => {
    api.put(
      `/influencers/update/status`,
      {
        id: selectedInfluencerId,
        status: newStatus,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    )
    .then(() => {
      setInfluencers((prevInfluencers) =>
        prevInfluencers.map((influencer) =>
          influencer.id === selectedInfluencerId
            ? { ...influencer, status: newStatus }
            : influencer
        )
      );
      setIsModalSemSucesso(false);
    })
    .catch((error) => {
      console.error("Erro ao atualizar status do influenciador:", error);
      Toastr("error", "Erro ao atualizar status.");
    });
  };

  const ativarModal = () => {
    setModalCadastrarInflu(true)
  }
  
  const handleModalSubmit = (newChatLink) => {
    setChatLink(newChatLink);
    setIsModalZapCaptadoOpen(false);
    setInfluencers((prevInfluencers) =>
      prevInfluencers.map((influencer) =>
        influencer.id === selectedInfluencerId
          ? { ...influencer, status: 4, chatLink: newChatLink }
          : influencer
      )
    );

    handleStatusUpdate(4)
  };

  function Updated() {
    Toastr("success", "Influencer Editado com sucesso!")
    setModalEditNew(false)
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  const submitRemarketing = remarketing => {
    setModalRemarketing(true)
    postRemarketing(idInfluRemarketing, true, remarketing)
  }

  const getTemaFromStorage = () => {
    return localStorage.getItem("tema") || "";
  };

  return (
    <div className="container">
      {fecharModalPayment && (
          <ModalFechadoPayment
            idInfluencer={selectedInfluencerId}
            open={fecharModalPayment}
            toogle={() => setFecharModalPayment(false)}
            confirm={handleConfirmFechadoPayment}
            updateInfluencers={handleStatusUpdate}
          />
        )}
        
      {isModalFechadoOpen && (
        <FechadoModal
          isOpen={isModalFechadoOpen}
          toggle={() => setIsModalFechadoOpen(false)}
          onConfirm={handleConfirmFechado}
        />
      )}

      {isModalZapCaptadoOpen && (
        <ModalZapCaptado
          isOpen={isModalZapCaptadoOpen}
          toggle={() => setIsModalZapCaptadoOpen(false)}
          idInfluencer={selectedInfluencerId}
          onSubmit={handleModalSubmit}
          setChatLink={setChatLink}
          chatLink={chatLink}
        />
      )}

      {modalRemarketing && (
        <Remarketing
          isOpen={modalRemarketing}
          toggle={() => setModalRemarketing(!modalRemarketing)}
          onSubmit={submitRemarketing}
          justification={remarketing}
          setJustification={setRemarketing}
        />
      )}

      {deleteInfluModal && (
        <ModalDeleteInfluencer
        isOpen={deleteInfluModal}
        attInflu={fetchInfluencersData()}
        />
      )}

      {modalEditNew && (
        <ModalUpdatedInfluencer
          profileData={profileData}
          profileDataAvatar={profileDataAvatar}
          contact={contact}
          Updated={Updated}
        />
      )}

      {isModalSemSucesso && (
        <ModalSemSucesso
          isOpen={isModalSemSucesso}
          toggle={() => setIsModalSemSucesso(false)}
          idInfluencer={selectedInfluencerId}
          onSubmit={submitJustification}
          justification={justification}
          setJustification={setJustification}
        />
      )}

      {modalCadastrarInflu && (
        <ModalCriarInfluencer
        isOpen={modalCadastrarInflu}
        setToggle={() => setModalCadastrarInflu(false)}
        Register={() => setModalCadastrarInflu(false)}
        />
      )}

      {modalEdit && (
        <ModalUpdatedInfluencer
          profileData={"1"}
          profileDataAvatar={"1"}
          contact={"1"}
          Updated={"1"}
        />
      )}
      
      <Col>
      <Col>
        <div className="filtro">
          <label>Filtrar por:</label>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="dia">Dia</option>
            <option value="semana">Semana</option>
            <option value="mes">Mês</option>
            <option value="todos">Todos</option>
          </select>
          {filter === "mes" && (
            <select
              value={selectedMonth}
              style={{ marginLeft: "5px" }}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="0">Janeiro</option> <option value="1">Fevereiro</option> <option value="2">Março</option> <option value="3">Abril</option> 
              <option value="4">Maio</option> <option value="5">Junho</option> <option value="6">Julho</option> <option value="7">Agosto</option>
              <option value="8">Setembro</option> <option value="9">Outubro</option> <option value="10">Novembro</option> <option value="11">Dezembro</option>
            </select>
          )}
        </div>
      </Col>
  
        <Col>
          {lanes.length > 0 ? (
            <div className="react-trello-board">
              <Board
                data={{ lanes }}
                laneStyle={laneStyle(tema)}
                style={boardStyle(tema)}
                components={{ Card }}
                onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => { handleCardMove(cardId, toLaneId); }}
              />
            </div>
          ) : (
            <Spinners setLoading={setLoading} />
          )}
        </Col>
      </Col>
      <Botao onClick={ativarModal}/>
  
    </div>
  );
};

export default Kanban;