const styles = {
    instagramIcon: {
      color: '#E4405F',
      fontSize: '1.2rem',
      marginTop: '9px',
    },
    button: {
        backgroundColor: '#0194a9', // Cor de fundo
        color: '#ffffff', // Cor do texto
        fontSize: '1rem', // Tamanho da fonte
        height: '2.5rem', // Altura do botão
        borderRadius: '0.8rem', // Bordas arredondadas
        paddingLeft: '1.25rem', // Padding esquerdo
        paddingRight: '1.25rem', // Padding direito
        paddingTop: '0.5rem', // Padding topo
        paddingBottom: '0.5rem', // Padding fundo
        cursor: 'pointer', // Cursor de clique
        textAlign: 'center', // Alinhamento do texto
        fontWeight: 700, // Peso da fonte
        border: 'none', // Remove a borda padrão
        outline: '2px solid transparent', // Contorno transparente
        transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)', // Transição suave
        marginRight: '10px', // Margem direita
      },
      icon: {
        fontSize: "20px",
        color: "#E4405F",
        marginRight: "5px"
      },
      button2: {
        backgroundColor: '#32a852',
        color: '#ffffff',
        fontSize: '1rem',
        height: '2.5rem',
        borderRadius: '0.8rem',
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 700,
        border: 'none',
        outline: '2px solid transparent', 
        transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
        marginRight: '10px',
      },
  };
  
  export default styles;
  