import React, { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, FormGroup, Label, Input } from "reactstrap"

import { AuthContext } from "contexts/auth"

import jobs from "../../../assets/images/companies/img-5.png"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper/modules"
import "../../../../node_modules/swiper/swiper.scss"
import api from "config/api"

const CandidateSection = () => {
  const { user } = useContext(AuthContext)
  const [topInfluencers, setTopInfluencers] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedOption, setSelectedOption] = useState("Registrations")

  const handleChange = event => {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    async function getTopInfluencers() {
        try {
            const response = await api.get(`/graphics/apg/admin/${user.userId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const trackings = response.data?.[0]?.Trackings || [];

            // Agrupar e somar os valores por TrackingCode
            const groupedTrackings = groupAndSumTrackings(trackings);

            // Ordenar os influenciadores com base na métrica selecionada
            const influencersOrdenados = groupedTrackings.sort(
                (a, b) => b[selectedOption] - a[selectedOption]
            );

            // Agrupar os influenciadores em pares para o Swiper
            const resultado = [];
            for (let i = 0; i < influencersOrdenados.length; i += 2) {
                resultado.push(influencersOrdenados.slice(i, i + 2));
            }

            setTopInfluencers(resultado);
        } catch (err) {
            console.error("Erro ao buscar os dados de tracking:", err);
        } finally {
            setLoading(false);
        }
    }

    getTopInfluencers();
}, [user.token, selectedOption]);




const groupAndSumTrackings = (trackings) => {
  const grouped = {};

  trackings.forEach(item => {
      const trackingCode = item.TrackingCode;

      if (!grouped[trackingCode]) {
          grouped[trackingCode] = {
              TrackingCode: trackingCode,
              Campaign: item.Campaign,
              Visitors: 0,
              Registrations: 0,
              FTD: 0,
              Deposits: 0,
          };
      }

      console.log("Item: ", item)
      grouped[trackingCode].Visitors += item.Visitors || 0;
      grouped[trackingCode].Registrations += item.Registrations || 0;
      grouped[trackingCode].FTD += item.FTD || 0;
      grouped[trackingCode].Deposits += item.Deposits || 0;
  });

  return Object.values(grouped);
};




  const formatToCurrency = value => {
    if (value == null) return "R$0,00"
    return `${value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div>
                  <h4
                    className="card-title mb-3"
                    style={{ marginLeft: "-20px", height: "105px" }}
                  >
                    Análises Detalhadas
                  </h4>
                  <p className="text-muted fa-1x">
                    Visualize dados complexos em formatos simples e
                    compreensíveis, permitindo identificar tendências e
                    oportunidades com precisão.
                  </p>
                  <div>
                    <Link
                      to="/tracking-codes"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-user-plus align-middle"></i>{" "}
                      Visualizar Estatísticas
                    </Link>
                  </div>
                </div>
                <div>
                  <img src={jobs} alt="" height="100" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={8}>
                  <h4 className="card-title mb-3" style={{ padding: "5px" }}>
                    Top Influencers por
                  </h4>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Input
                      type="select"
                      name="select"
                      id="selectOption"
                      value={selectedOption}
                      onChange={handleChange}
                    >
                      <option value="Visitors">Visitas</option>
                      <option value="Registrations">Registros</option>
                      <option value="FTD">FTD</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
            >
                <div className="carousel-inner">
                    {topInfluencers.map((item, i) => (
                        <SwiperSlide key={i}>
                            <div className="carousel-item active" data-bs-interval="3000">
                                {item[0] && (
                                    <div className="bg-light p-3 d-flex mb-3 rounded">
                                        <img
                                            src={item[0]?.photo || "https://i.ibb.co/y6nkQKW/placeholder.jpg"}
                                            alt=""
                                            className="avatar-sm rounded me-3"
                                        />
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15 mb-2">
                                                <a
                                                    href={`/tracking-perfil/${item[0]?.TrackingCode}`}
                                                    className="text-body gap-2"
                                                >
                                                    {item[0]?.Campaign}
                                                </a>{" "}
                                                <span
                                                    style={{ marginLeft: "3px" }}
                                                    className="badge badge-soft-info ml-3"
                                                >
                                                    {selectedOption === "Deposits"
                                                        ? formatToCurrency(item[0]?.Deposits)
                                                        : item[0]?.[selectedOption]}
                                                </span>
                                            </h5>
                                            <h6 className="mb-0 text-muted">
                                                @{item[0]?.TrackingCode}
                                            </h6>
                                        </div>
                                    </div>
                                )}
                                {item[1] && (
                                    <div className="bg-light p-3 d-flex">
                                        <img
                                            src={item[1]?.photo || "https://i.ibb.co/y6nkQKW/placeholder.jpg"}
                                            alt=""
                                            className="avatar-sm rounded me-3"
                                        />
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15 mb-2">
                                                <a href="" className="text-body">
                                                    {item[1]?.Campaign}
                                                </a>
                                                <span
                                                    style={{ marginLeft: "3px" }}
                                                    className="badge badge-soft-info"
                                                >
                                                    {selectedOption === "Deposits"
                                                        ? formatToCurrency(item[1]?.Deposits)
                                                        : item[1]?.[selectedOption]}
                                                </span>
                                            </h5>
                                            <h6 className="mb-0 text-muted">
                                                @{item[1]?.TrackingCode}
                                            </h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CandidateSection
