import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

function FechadoModal({ isOpen, toggle, onConfirm }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="custom-modal-header" toggle={toggle}>Confirmar Fechamento</ModalHeader>
      <ModalBody className="custom-text-padrao">
        Tem certeza de que deseja fechar este influenciador?
        Esta ação moverá o influenciador para a categoria "Fechado".
      </ModalBody>
      <ModalFooter>
        <button className="button-cancelar" onClick={toggle}>
          Cancelar
        </button>
        <button className="button-confirmar" onClick={onConfirm}>
          Confirmar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default FechadoModal;
