import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  InputGroupText,
  Label,
  Button,
} from "reactstrap"
import ChartSection from "./ChartSection"
import SalesAnalytics2 from "./sales-analytics"
import Section from "./Section"
import StatisticsApplications from "./StatisticsApplications"
import { AuthContext } from "contexts/auth"
import api from "config/api"
import CandidateSection from "./CandidateSection"
import Loading from "components/Ui/LoadingCircle"
import DatePicker from "react-flatpickr"

import "react-datepicker/dist/react-datepicker.css"

const DashboardJob = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chartsData, setChartsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await api.get(`/graphics/apg/admin/${user.userId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const apiData = response.data;
            const processedData = processApiData(apiData);
            const chartData = getChartData(processedData);
            setChartsData(chartData);
            setData(apiData);
        } catch (error) {
            console.error("Erro ao buscar os dados:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [user.token, user.userId]);

  const calculatePercentageChange = (current, previous) => {
    if (!previous) return 0;
    return ((current - previous) / Math.abs(previous)) * 100;
};

const processApiData = (apiData) => {
  const geralData = apiData?.[0]?.Geral?.totals || {};
  const ganhoTotal = apiData?.[0]?.Geral?.ganhoTotal || 0;

  const mensalData = (apiData?.[0]?.TotalsByMonth || []).map(item => {
      const totals = item?.Totals?.totals || {};
      return {
          monthYear: item?.Month || '',
          Visitors: totals.Visitors || 0,
          Registrations: totals.Registrations || 0,
          FTDs: totals.FTD || 0,
          Deposits: totals.Deposits || 0,
          NGR: totals.NGR || 0,
          GanhoTotal: item?.Totals?.ganhoTotal || 0,
      };
  });

  return {
      geral: {
          Visitors: geralData.Visitors || 0,
          Registrations: geralData.Registrations || 0,
          FTDs: geralData.FTD || 0,
          Deposits: geralData.Deposits || 0,
          NGR: geralData.NGR || 0,
          GanhoTotal: ganhoTotal,
      },
      mensal: mensalData,
  };
};

const groupDataByMonth = (diarioData) => {
  const groupedData = {};

  (diarioData || []).forEach(item => {
      const monthYear = item?.Period?.slice(0, 7) || '';
      if (monthYear) {
          if (!groupedData[monthYear]) {
              groupedData[monthYear] = { Register: 0, CPA: 0, FTDs: 0 };
          }
          groupedData[monthYear].Register += item?.Register || 0;
          groupedData[monthYear].CPA += item?.CPA || 0;
          groupedData[monthYear].FTDs += item?.FTDs || 0;
      }
  });

  return groupedData;
};

const getChartData = (processedData) => {
  const { geral, mensal } = processedData;

  return [
      {
          id: 1,
          title: 'Número de Visitas',
          price: geral.Visitors.toLocaleString("pt-BR"),
          perstangeValue: 100,
          bagdeColor: geral.Visitors >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "Visitas Mensais",
                  data: mensal.map(item => item.Visitors),
              },
          ],
          color: '["--bs-primary", "--bs-transparent"]',
      },
      {
          id: 2,
          title: 'Registros',
          price: geral.Registrations.toLocaleString("pt-BR"),
          perstangeValue: 100,
          bagdeColor: geral.Registrations >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "Registros Mensais",
                  data: mensal.map(item => item.Registrations),
              },
          ],
          color: '["--bs-success", "--bs-transparent"]',
      },
      {
          id: 3,
          title: "FTD's",
          price: geral.FTDs.toLocaleString("pt-BR"),
          perstangeValue: 100,
          bagdeColor: geral.FTDs >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "FTD's Mensais",
                  data: mensal.map(item => item.FTDs),
              },
          ],
          color: '["--bs-warning", "--bs-transparent"]',
      },
      {
          id: 4,
          title: 'Depósitos',
          price: geral.Deposits.toLocaleString("pt-BR"),
          perstangeValue: 100,
          bagdeColor: geral.Deposits >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "Depósitos Mensais",
                  data: mensal.map(item => item.Deposits),
              },
          ],
          color: '["--bs-danger", "--bs-transparent"]',
      },
      {
          id: 5,
          title: 'NGR (Lucro Real)',
          price: geral.NGR.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          perstangeValue: 100,
          bagdeColor: geral.NGR >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "NGR Mensal",
                  data: mensal.map(item => item.NGR),
              },
          ],
          color: '["--bs-info", "--bs-transparent"]',
      },
      {
          id: 6,
          title: 'Ganho Total',
          price: geral.GanhoTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          perstangeValue: 100,
          bagdeColor: geral.GanhoTotal >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "Ganho Total Mensal",
                  data: mensal.map(item => item.GanhoTotal),
              },
          ],
          color: '["--bs-success", "--bs-transparent"]',
      },
  ];
};


const prepareSalesAnalyticsData = (apiData) => {
  const trackings = apiData?.[0]?.Trackings || [];

  return trackings.map(item => ({
      Period: item.Day || '',
      Visitors: item.Visitors || 0,
      Registrations: item.Registrations || 0,
      FTDs: item.FTD || 0,
      Deposits: item.Deposits || 0,
  }));
};


const processedData = processApiData(data);

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div className="page-content">
          <Container fluid style={{ marginTop: "-30px" }}>
            <Section />

            <ChartSection chartsData={chartsData} />

            <Row>
            <StatisticsApplications
              Visits={processedData.geral.Visitors || 0}
              Registrations={processedData.geral.Registrations || 0}
              FTDs={processedData.geral.FTDs || 0}
              Deposits={processedData.geral.Deposits || 0}
            />
              <SalesAnalytics2
                data={data}
                dataDias={prepareSalesAnalyticsData(data)}
                dataColors='["--bs-primary","--bs-success","--bs-danger", "--bs-warning"]'
                dashboard={true}
            />

            </Row>
            <Row>
              <CandidateSection />
            </Row>

          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default DashboardJob
