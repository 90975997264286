import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"
import Loading from "components/Ui/LoadingCircle"
import { Link } from "react-router-dom"

const List = ({ listData }) => {
  
  const [influencer, setInfluencer] = useState()
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [showMetaFields, setShowMetaFields] = useState(false)
  const [meta, setMeta] = useState("")
  const [valor, setValor] = useState("")
  const [bonus, setBonus] = useState("")
  const [dateInit, setDateInit] = useState("")
  const [dateFinal, setDateFinal] = useState("")
  const [formattedDateInit, setFormattedDateInit] = useState("")
  const [formattedDateFinal, setFormattedDateFinal] = useState("")
  const [ultimaMeta, setUltimaMeta] = useState("")

  const handleUpdateMetaClick = () => {
    if (ultimaMeta) {
      setMeta(ultimaMeta.meta || "")
      setValor(ultimaMeta.valueMin || "")
      setBonus(ultimaMeta.valueBonus || "")
      setFormattedDateInit(ultimaMeta.dateInit || "")
      setFormattedDateFinal(ultimaMeta.dateFinal || "")
      setDateInit(formatDate(ultimaMeta.dateInit) || "")
      setDateFinal(formatDate(ultimaMeta.dateFinal) || "")
    }
    setShowMetaFields(true)
  }

  const formatCurrency = value => {
    if (value == null) {
      return ""
    }
    return parseFloat(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    })
  }

  const handleCurrencyChange = (e, setter) => {
    let value = e.target.value
    value = value.replace(/\D/g, "")
    value = (value / 100).toFixed(2)
    value = formatCurrency(value)
    setter(value)
  }

  const handleSaveMeta = async () => {
    if (isNaN(new Date(dateFinal).getTime())) {
      Toastr("danger", "Data final inválida.")
      return
    }

    try {
      const response = await api.post(
        "/profile/influencer/meta",
        {
          influencer: influencer.influId,
          meta: meta,
          valueMin: valor,
          valueBonus: bonus,
          dateInit: formattedDateInit,
          dateFinal: formattedDateFinal,
          isDailyPayment: false,
          isAdvanceValue: false,
          advancedValue: "R$ 0,00",
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )

      if (response.status === 201) {
        setShowMetaFields(false)
        Toastr("success", "Meta criada com sucesso!")
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        Toastr("danger", "Erro ao salvar a meta: " + error.message)
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status
        if (status === 404) {
          Toastr("error", "Já existe uma meta para esse periodo")
        } else if (status === 500) {
          Toastr("error", "Não foi possivel criar")
        } else {
          Toastr("danger", `Erro ao salvar a meta: ${error.message}`)
        }
      } else {
        Toastr("danger", "Erro ao salvar a meta: " + error.message)
      }
      console.error("Erro ao salvar a meta:", error)
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const offset = +3
    const localDate = new Date(date.getTime() + offset * 60 * 60 * 1000)

    const formattedDate = localDate.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "America/Sao_Paulo",
    })

    return formattedDate
  }

  function handlePutIsPaymentSuccess(id, isNote) {
    profileMeta(id, isNote).then(() => {
      Toastr("success", "Sucesso!")

      setTimeout(() => {
        window.location.reload()
      }, 3000)
    })
  }

  const obterDatas = () => {
    const hoje = new Date()
    const diaSemana = hoje.getDay()

    const ultimaSegunda = new Date(hoje)
    ultimaSegunda.setDate(hoje.getDate() - ((diaSemana + 6) % 7))

    if (diaSemana === 1) {
      ultimaSegunda.setDate(ultimaSegunda.getDate() - 7)
    }

    const proximoDomingo = new Date(hoje)

    if (diaSemana === 1) {
      proximoDomingo.setDate(hoje.getDate() - 1)
    } else {
      proximoDomingo.setDate(hoje.getDate() + (7 - diaSemana))
    }

    const formatarData = data => {
      const dia = data.getDate().toString().padStart(2, "0")
      const mes = (data.getMonth() + 1).toString().padStart(2, "0")
      const ano = data.getFullYear()
      return `${ano}-${mes}-${dia}`
    }

    return {
      ultimaSegunda: formatarData(ultimaSegunda),
      proximoDomingo: formatarData(proximoDomingo),
    }
  }

  const datas = obterDatas()

  const handleModal = () => {
    setShowModal(!showModal)
    if (!showModal) {
      setInfluencer(null)
      setMeta("")
      setValor("")
      setBonus("")
      setDateInit("")
      setDateFinal("")
    }
  }

  const getUltimaMeta = async () => {
    if (influencer && influencer.influId) {
      try {
        const response = await api.get(
          `/profile/influencer/meta/${influencer.influId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        setUltimaMeta(response.data)
      } catch (error) {
        console.error("Erro ao buscar última meta:", error)
      }
    }
  }
  const profileMeta = async (id, isNote) => {
    try {
      await api.put(
        `/profile/influencer/meta/update/nota/${id}`,
        {
          isPaymentSuccess: !isNote,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
    } catch (error) {
      console.error("Erro:", error)
    }
  }

  useEffect(() => {
    if (influencer) {
      getUltimaMeta()
    }
  }, [influencer])

  const handleModalOpen = async selectedInfluencer => {
    setInfluencer(selectedInfluencer)
    await new Promise(resolve => setTimeout(resolve, 0))
    await getUltimaMeta()
    setShowModal(true)
  }

  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Maria Eduarda":
        return "badge badge-soft-success"
      case "Jadson Nogueira":
        return "badge badge-soft-info"
      case "Karol":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const sectorBadgeCampaigns = option => {
    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info"
      case "Betano e SuperBet":
        return "badge badge-soft-success"
      case "Betano":
        return "badge badge-soft-danger"
      default:
        return "badge badge-soft-warning"
    }
  }

  const handleDateInitChange = e => {
    const rawDate = e.target.value
    setDateInit(rawDate)
    const formattedDate = `${rawDate}T00:00:00.000Z`
    setFormattedDateInit(formattedDate)
  }

  const handleDateFinalChange = e => {
    const rawDate = e.target.value
    setDateFinal(rawDate)
    const formattedDate = `${rawDate}T23:59:59.999Z`
    setFormattedDateFinal(formattedDate)
  }

  return (
    <React.Fragment>
      <Row>
        {listData.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.influId
                          ? sectorBadgeClasses(influencer.responsavel)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.influId
                        ? influencer.responsavel
                        : "Nome indisponível"}
                    </span>

                    <span
                      style={{ marginLeft: "10px" }}
                      className={
                        influencer && influencer.campaign
                          ? sectorBadgeCampaigns(influencer.campaign)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer ? influencer.campaign : "Nome indisponível"}
                    </span>
                  </div>
                </div>
                <div className="text-center mb-3">
                  <img
                    src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                    alt="Logo da viraw"
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer?.influencer}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer?.username}
                  </p>
                  <p className="mb-0 text-muted font-size-12">
                    {influencer?.Pagar?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })}
                  </p>
                  <Button
                    style={{ width: "100%", marginTop: "10px" }}
                    className={`btn d-block ${
                      influencer?.isEmitNote ? "btn-success" : "btn-danger"
                    }`}
                    onClick={() =>
                      handlePutIsPaymentSuccess(
                        influencer.idMeta,
                        influencer.isEmitNote
                      )
                    }
                  >
                    {influencer?.isEmitNote
                      ? "Nota Emitida"
                      : "Não emitiu nota"}{" "}
                  </Button>
                </div>

                <Row>
                  <Col md="6">
                    <Button
                      style={{ width: "100%" }}
                      className="btn btn-soft-primary d-block"
                      onClick={() => handleModalOpen(influencer)}
                    >
                      Detalhes
                    </Button>
                  </Col>
                  <Col md="6">
                    <Link to={`/influencer/${influencer?.plugchat}`}>
                      <Button
                        style={{ width: "100%" }}
                        className="btn btn-soft-primary d-block"
                      >
                        Perfil
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal isOpen={showModal} toggle={handleModal}>
        <ModalHeader toggle={handleModal}>Detalhes</ModalHeader>

        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <h3>{influencer?.influencer}</h3>
            <h5>{`${formatDate(influencer?.inicio)} à ${formatDate(
              influencer?.final
            )}`}</h5>
          </div>
          <hr />

          <h6>Meta: {influencer?.meta}</h6>
          <h6>Valor: {influencer?.valor}</h6>
          <h6>Bônus: {influencer?.bonus}</h6>
          <h6>Responsável: {influencer?.responsavel}</h6>

          <hr />
          <h4>Parcial</h4>
          <br />

          <Row>
            <Col md={4}>
              <h4>
                {" "}
                <b>FTDs: </b>
                {influencer?.FTDs}{" "}
              </h4>
            </Col>
            <Col md={8}>
              <h4>
                {" "}
                <b>
                  {influencer?.Pagar.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                  })}{" "}
                </b>{" "}
              </h4>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {influencer?.Pagar === "Atualize a meta" && (
            <>
              {!showMetaFields ? (
                <Button
                  onClick={handleUpdateMetaClick}
                  className="btn btn-primary"
                >
                  Atualizar a meta
                </Button>
              ) : (
                <div style={{ width: "100%" }}>
                  <Input
                    type="text"
                    placeholder="Meta"
                    value={meta}
                    onChange={e => setMeta(e.target.value)}
                    className="mb-2"
                  />
                  <Input
                    type="text"
                    placeholder="Valor minimo"
                    value={valor}
                    onChange={e => handleCurrencyChange(e, setValor)}
                    className="mb-2"
                  />
                  <Input
                    type="text"
                    placeholder="Valor Excedente"
                    value={bonus}
                    onChange={e => handleCurrencyChange(e, setBonus)}
                    className="mb-2"
                  />
                  <Input
                    type="date"
                    placeholder="Data de Início"
                    value={dateInit}
                    onChange={handleDateInitChange}
                    className="mb-2"
                  />
                  <Input
                    type="date"
                    placeholder="Data de Final"
                    value={dateFinal}
                    onChange={handleDateFinalChange}
                    className="mb-2"
                  />

                  <Button onClick={handleSaveMeta} className="btn btn-info">
                    Salvar Meta
                  </Button>
                </div>
              )}
            </>
          )}
          <Button color="primary" onClick={handleModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default List
