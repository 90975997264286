import React from 'react';
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const JobWidgetCharts = ({ dataColors, series }) => {
    var areacharteathereumColors = getChartColorsArray(dataColors);


    var options = {
        chart: {
            width: 100,
            height: 46,
            type: "area",
            sparkline: {
                enabled: true,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 1.5,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
            },
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                },
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    if (series[seriesIndex].number === true) {
                        return value.toLocaleString('pt-BR'); 
                    } else {
                        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                }
            },
            marker: {
                show: false
            },
            custom: function({ series, seriesIndex, dataPointIndex, number}) {
                var monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho"];
                var formattedValue = series[seriesIndex][dataPointIndex].toLocaleString('pt-BR');
                if (seriesIndex === 0) { 
                    return "<div>" + monthNames[dataPointIndex] + ": " + formattedValue + "</div>";
                } else {
                    return "<div>" + monthNames[dataPointIndex] + ": " + formattedValue + "</div>";
                }
            }
        },
        colors: areacharteathereumColors,
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height="46"
                number={series[0].number}
                width="200"
                className="apex-charts"
            />
        </React.Fragment>
    );
}

const StatisticsApplicationsChart = ({ seriesData, dataColors }) => {
    const statisticsApplicationColors = getChartColorsArray(dataColors);

    const series = [
        {
            name: 'Visitas',
            type: 'column',
            data: [seriesData.Visits || 0],
        },
        {
            name: 'Registros',
            type: 'column',
            data: [seriesData.Registrations || 0],
        },
        {
            name: 'FTD',
            type: 'column',
            data: [seriesData.FTDs || 0],
        },
        {
            name: 'Depósitos',
            type: 'column',
            data: [seriesData.Deposits || 0],
        },
    ];

    const options = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: true,
            },
        },
        legend: {
            show: true,
        },
        stroke: {
            width: [0, 0, 2, 2],
            curve: 'smooth',
        },
        plotOptions: {
            bar: {
                columnWidth: '5%',
            },
        },
        colors: statisticsApplicationColors,
        xaxis: {
            categories: ["Jan/2025", "Fev/2025", "Mar/2025", "Abr/2025", "Mai/2025"],
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (value) => value.toLocaleString('pt-BR'),
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="450"
                className="apex-charts pb-3 p-4"
            />
        </React.Fragment>
    );
};

const ReceivedTimeCharts = ({ dataColors }) => {
    var statisticsApplicationColors = getChartColorsArray(dataColors);

    const series = [{
        name: 'Received Application',
        data: [34, 44, 54, 21, 12, 43, 33, 80, 66]
    }];
    var options = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: true,
            },
        },
        legend: {
            show: true,
            offsetY: 10,
        },
        stroke: {
            width: [0, 0, 2, 2],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '5%',
            }
        },
        fill: {
            opacity: [1, 1, 0.1, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        labels: ["Jan/2025", "Fev/2025", "Mar/2025", "Abr/2025", "Mai/2025"],
        colors: statisticsApplicationColors,
        markers: {
            size: 0
        },
        xaxis: {
            categories: ["Jan/2025", "Fev/2025", "Mar/2025", "Abr/2025", "Mai/2025"],
            type: 'category',
        },
        yaxis: {
            title: {
                text: 'Valores Mensais',
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toLocaleString('pt-BR');
                    }
                    return y;
                }
            }
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="393px"
                width="456px"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export { JobWidgetCharts, StatisticsApplicationsChart, ReceivedTimeCharts };
