import React, { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, FormGroup, Label, Input } from "reactstrap"

import { AuthContext } from "contexts/auth"

import jobs from "../../../assets/images/companies/img-5.png"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay } from "swiper/modules"
import "../../../../node_modules/swiper/swiper.scss"
import api from "config/api"

const CandidateSection = () => {
  const { user } = useContext(AuthContext)
  const [topInfluencers, setTopInfluencers] = useState([])
  const [topInfluencersFTDs, setTopInfluencersFTDs] = useState([])
  const [topInfluencersDeposits, setTopInfluencersDeposits] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedOption, setSelectedOption] = useState("Registrations")

  const handleChange = event => {
    setSelectedOption(event.target.value)
  }

  useEffect(() => {
    async function getTopInfluencers() {
      try {
        const response = await api.get("/tracking/superbet/geral/all", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        const data = response.data

        const influencersOrdenados = data.sort(
          (a, b) => b.Total?.[selectedOption] - a.Total?.[selectedOption]
        )

        const influencersOrdenadosArching = influencersOrdenados.filter(
          item => item.archive === false
        )

        const top10Influencers = influencersOrdenadosArching.slice(0, 10)

        const resultado = []

        for (let i = 0; i < top10Influencers.length; i += 2) {
          resultado.push(top10Influencers.slice(i, i + 2))
        }

        setTopInfluencers(resultado)
      } catch (err) {
        console.error(err)
      }
    }
    getTopInfluencers()
  }, [user.token, selectedOption])

  const formatToCurrency = value => {
    if (value == null) return "R$0,00"
    return `${value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div>
                  <h4
                    className="card-title mb-3"
                    style={{ marginLeft: "-20px", height: "105px" }}
                  >
                    Análises Detalhadas
                  </h4>
                  <p className="text-muted fa-1x">
                    Visualize dados complexos em formatos simples e
                    compreensíveis, permitindo identificar tendências e
                    oportunidades com precisão.
                  </p>
                  <div>
                    <Link
                      to="/tracking-codes"
                      className="btn btn-primary btn-sm"
                    >
                      <i className="bx bx-user-plus align-middle"></i>{" "}
                      Visualizar Estatísticas
                    </Link>
                  </div>
                </div>
                <div>
                  <img src={jobs} alt="" height="100" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={8}>
                  <h4 className="card-title mb-3" style={{ padding: "5px" }}>
                    Top Influencers por
                  </h4>
                </Col>
                <Col lg={4}>
                  <FormGroup>
                    <Input
                      type="select"
                      name="select"
                      id="selectOption"
                      value={selectedOption}
                      onChange={handleChange}
                    >
                      <option value="Registrations">Registros</option>
                      <option value="CPACount">CPA</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              {/* <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                <div className="carousel-inner">
                  {topInfluencers.map((item, i) => (
                    <SwiperSlide key={i}>
                      <div
                        className="carousel-item active"
                        data-bs-interval="3000"
                      >
                        {item[0] && (
                          <div className="bg-light p-3 d-flex mb-3 rounded">
                            <img
                              src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                              alt=""
                              className="avatar-sm rounded me-3"
                            />
                            <div className="flex-grow-1">
                              <h5 className="font-size-15 mb-2">
                                <a
                                  href={`/tracking-perfil/${item[0]?.username}`}
                                  className="text-body gap-2"
                                >
                                  {item[0]?.fullname}
                                </a>{" "}
                                <span
                                  style={{ marginLeft: "3px" }}
                                  className="badge badge-soft-info ml-3"
                                >
                                  {selectedOption === "Deposits"
                                    ? formatToCurrency(item[0]?.Total?.Deposits)
                                    : item[0]?.Total?.[selectedOption]}
                                </span>
                              </h5>
                              <h6 className="mb-0 text-muted">
                                @{item[0]?.username}
                              </h6>
                            </div>
                          </div>
                        )}
                        {item[1] && (
                          <div className="bg-light p-3 d-flex">
                            <img
                              src="https://i.ibb.co/y6nkQKW/461085513-1715142105981321-1128849984098454117-n.jpg"
                              alt=""
                              className="avatar-sm rounded me-3"
                            />
                            <div className="flex-grow-1">
                              <h5 className="font-size-15 mb-2">
                                <a href="" className="text-body">
                                  {item[1]?.fullname}
                                </a>
                                <span
                                  style={{ marginLeft: "3px" }}
                                  className="badge badge-soft-info"
                                >
                                  {selectedOption === "Deposits"
                                    ? formatToCurrency(item[1]?.Total?.Deposits)
                                    : item[1]?.Total?.[selectedOption]}
                                </span>
                              </h5>
                              <h6 className="mb-0 text-muted">
                                @{item[1]?.username}
                              </h6>
                            </div>
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CandidateSection
