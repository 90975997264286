import React, { useContext } from "react";
import {
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import * as yup from "yup";
import Toastr from "../../../components/Ui/Notifications";
import api from "config/api";
import { AuthContext } from "contexts/auth";

function ChatLinkModal({ isOpen, toggle, onSubmit, chatLink, setChatLink, idInfluencer}) {

  const {user} = useContext(AuthContext);
  
  const linkSchema = yup
    .string()
    .test(
      "is-url",
      "Por favor, insira um link válido.",
      (value) => {
        const urlPattern = /^(https?:\/\/)?(www\.)?[\w-]+(\.[a-z]+)+/i;
        return urlPattern.test(value);
      }
    )
    .required("O link é obrigatório.");

  const handleSubmit = async () => {
    try {
      const formattedLink = chatLink.startsWith("http") ? chatLink : `https://${chatLink}`;
      await linkSchema.validate(formattedLink);
      setChatLink(formattedLink); 

      await api.put(`/influencers/update/link`,
        { 
          id: idInfluencer, 
          linkConversa: formattedLink
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      
  
      Toastr("success", "Link enviado com sucesso!");
      onSubmit();
    } catch (error) {
      Toastr("error", error.message); 
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="custom-modal-header" toggle={toggle}>Inserir Link da Conversa</ModalHeader>
      <ModalBody>
        <Label className="custom-text-geral" for="chatLink">Link da Conversa</Label>
        <Input
          type="text"
          className="custom-text-geral"
          id="chatLink"
          value={chatLink}
          onChange={(e) => setChatLink(e.target.value)}
          placeholder="Digite o link da conversa aqui"
        />
      </ModalBody>
      <ModalFooter>
        <button className="button-cancelar" onClick={toggle}>
          Cancelar
        </button>
        <button className="button-confirmar" onClick={handleSubmit}>
          Confirmar
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ChatLinkModal;
