import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "contexts/auth";
import { Card, CardBody, Col, Container, Row, Input } from "reactstrap";
import api from "config/api";
import Spinners from "components/Common/Spinner";
import Breadcrumbs from "components/Common/Breadcrumb";
import List from "./List";

const CollaboratorsList = () => {
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [listPayments, setListPayments] = useState([]);
  const [listPaymentsOriginal, setListPaymentsOriginal] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState("mensal");
  const { user } = useContext(AuthContext);

  const calculateMetrics = (influencers) => {
    const currentPeriod = getCurrentPeriod().period;

    return influencers.map((influencer) => {
      const currentMonthData = influencer?.Mensal?.find(
        (data) => data.Period === currentPeriod
      );

      const clicks = currentMonthData?.Clicks || 0;
      const registrations = currentMonthData?.Registrations || 0;
      const firstDepositCount = currentMonthData?.FirstDepositCount || 0;
      const seguidores = influencer?.followers || 0;
      const faturamento =
        influencer?.valueMin && firstDepositCount > 0
          ? parseFloat(influencer.valueMin.replace("R$", "")) * firstDepositCount
          : 0;

      return {
        ...influencer,
        clicks,
        registrations,
        firstDepositCount,
        faturamento,
        seguidores,
      };
    });
  };

  const formatFaturamento = (value) => `R$ ${value.toFixed(2)}`;

  const getSaques = async () => {
    try {
      const response = await api.get("/tracking/superbet/geral/all", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const captadorId = user?.userId;
      const filteredData = response.data.filter((item) => {
        const isFromCaptador = item.captador?.id === captadorId;
        const isArchived = item.archive === true;
        const currentMonthData = item?.Mensal?.find(
          (data) => data.Period === getCurrentPeriod().period
        );
        const hasFTDs = currentMonthData?.FirstDepositCount > 0;

        return isFromCaptador && (!isArchived || hasFTDs);
      });

      const calculatedData = calculateMetrics(filteredData);
      setListPaymentsOriginal(calculatedData);
      setListPayments(calculatedData);
    } catch (error) {
      console.error("Error fetching influencers:", error);
    }
  };

  const getCurrentPeriod = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    return { period: `${year}-${month}`, monthName: now.toLocaleString("default", { month: "long" }) };
  };

  useEffect(() => {
    let filteredList = [...listPaymentsOriginal];
  
    // Filtrar itens com valueMin inválido
    filteredList = filteredList.filter(
      (influencer) => influencer?.valueMin !== undefined && influencer?.valueMin !== null
    );
  
    if (searchValue) {
      filteredList = filteredList.filter((influencer) =>
        influencer.username.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  
    if (selectedDateFilter === "total") {
      filteredList = filteredList.map((influencer) => {
        const valueMin = influencer?.valueMin
          ? String(influencer.valueMin)
              .replace("R$", "")
              .replace(",", ".")
              .trim()
          : "0";
  
        const totalDepositCount = influencer?.Total?.FirstDepositCount || 0;
        return {
          ...influencer,
          clicks: influencer?.Total?.Clicks || 0,
          registrations: influencer?.Total?.Registrations || 0,
          firstDepositCount: totalDepositCount,
          faturamento: parseFloat(valueMin) * totalDepositCount || 0,
        };
      });
    }
  
    setListPayments(filteredList);
  }, [selectedDateFilter, selectedFilter, listPaymentsOriginal, searchValue]);
  

  useEffect(() => {
    getSaques();
  }, [user.token]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Influencers" breadcrumbItem="Métricas dos meus Influenciadores" />
          <Row>
            <Col lg={12}>
              <Card className="collaborator-filter">
                <CardBody>
                  <form>
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <Input
                          type="text"
                          placeholder="Buscar Influenciador"
                          onChange={(e) => setSearchValue(e.target.value)}
                          value={searchValue}
                        />
                      </Col>
                      <Col xs="3" className="mb-3">
                        <Input
                          type="select"
                          onChange={(e) => setSelectedFilter(e.target.value)}
                          value={selectedFilter}
                        >
                          <option value="">Sem ordenação</option>
                          <option value="firstDepositCount">FTD</option>
                          <option value="clicks">Cliques</option>
                          <option value="registrations">Cadastros</option>
                          <option value="seguidores">Seguidores</option>
                          <option value="faturamento">Faturamento</option>
                        </Input>
                      </Col>
                      <Col xs="3" className="mb-3">
                        <Input
                          type="select"
                          onChange={(e) => setSelectedDateFilter(e.target.value)}
                          value={selectedDateFilter}
                        >
                          <option value="mensal">Mês Atual</option>
                          <option value="diario">Último Dia</option>
                          <option value="total">Total</option>
                        </Input>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List
              listData={listPayments.map((item) => ({
                ...item,
                faturamentoFormatted: formatFaturamento(item.faturamento),
              }))}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollaboratorsList;
