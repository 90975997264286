import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  InputGroupText,
  Label,
  Button,
} from "reactstrap"
import ChartSection from "./ChartSection"
import SalesAnalytics2 from "./sales-analytics"
import Section from "./Section"
import StatisticsApplications from "./StatisticsApplications"
import { AuthContext } from "contexts/auth"
import api from "config/api"
import CandidateSection from "./CandidateSection"
import Loading from "components/Ui/LoadingCircle"
import DatePicker from "react-flatpickr"

import "react-datepicker/dist/react-datepicker.css"

const DashboardJob = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [chartsData, setChartsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await api.get(`/graphics/xp/admin/${user.userId}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            const apiData = response.data;
            const processedData = processApiData(apiData);
            const chartData = getChartData(processedData);
            setChartsData(chartData);
            setData(apiData);
        } catch (error) {
            console.error("Erro ao buscar os dados:", error);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [user.token, user.userId]);

  const calculatePercentageChange = (current, previous) => {
    if (!previous) return 0;
    return ((current - previous) / Math.abs(previous)) * 100;
};

const processApiData = (apiData) => {
  const mensalData = (apiData?.Mensal || []).map(item => ({
      monthYear: item?.monthYear || '',
      Register: item?.Register || 0,
      CPA: item?.CPA || 0,
      GanhosGerais: (item?.CPA || 0) * 60, 
  }));

  const diarioData = (apiData?.Diario || []).map(item => ({
      Period: item?.Period || '',
      Register: item?.Register || 0,
      CPA: item?.CPA || 0,
      GanhosGerais: (item?.CPA || 0) * 60,
  }));

  const groupedDiario = groupDataByMonth(diarioData);

  const geralData = {
      Register: apiData?.Geral?.Register || 0,
      CPA: apiData?.Geral?.CPA || 0,
      GanhosGerais: (apiData?.Geral?.CPA || 0) * 60,
  };

  return {
      mensal: mensalData,
      diario: groupedDiario,
      geral: geralData,
  };
};



const groupDataByMonth = (diarioData) => {
  const groupedData = {};

  (diarioData || []).forEach(item => {
      const monthYear = item?.Period?.slice(0, 7) || '';
      if (monthYear) {
          if (!groupedData[monthYear]) {
              groupedData[monthYear] = { Register: 0, CPA: 0, FTDs: 0 };
          }
          groupedData[monthYear].Register += item?.Register || 0;
          groupedData[monthYear].CPA += item?.CPA || 0;
          groupedData[monthYear].FTDs += item?.FTDs || 0;
      }
  });

  return groupedData;
};

const getChartData = (processedData) => {
  const { geral, mensal, diario } = processedData;

  const diarioFTDsData = Object.keys(diario || {}).map(monthYear => {
      return diario[monthYear]?.FTDs || 0;
  });

  return [
      {
          id: 1,
          title: 'Registros no Período',
          price: geral.Register.toLocaleString("pt-BR"),
          perstangeValue: 100,
          bagdeColor: geral.Register >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "Registros Mensais",
                  data: mensal.map(item => item.Register),
              },
          ],
          color: '["--bs-success", "--bs-transparent"]',
      },
      {
          id: 2,
          title: 'CPA no Período',
          price: geral.CPA.toLocaleString("pt-BR"),
          perstangeValue: 100,
          bagdeColor: geral.CPA >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "CPA Mensal",
                  data: mensal.map(item => item.CPA),
              },
          ],
          color: '["--bs-primary", "--bs-transparent"]',
      },
      {
          id: 3,
          title: "Ganhos Gerais no Período",
          price: geral.GanhosGerais.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          perstangeValue: 100,
          bagdeColor: geral.GanhosGerais >= 0 ? "success" : "danger",
          seriesData: [
              {
                  name: "Ganhos Gerais Mensais",
                  data: mensal.map(item => item.GanhosGerais),
              },
          ],
          color: '["--bs-warning", "--bs-transparent"]',
      },
  ];
};

const prepareSalesAnalyticsData = (apiData) => {
  return apiData?.Diario?.map(item => ({
      Period: item.Period || '',
      Register: item.Register || 0,
      CPA: item.CPA || 0,
      GanhosGerais: (item.CPA || 0) * 60,
  })) || [];
};


  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div className="page-content">
          <Container fluid style={{ marginTop: "-30px" }}>
            <Section />

            <ChartSection chartsData={chartsData} />

            <Row>
            <StatisticsApplications
              Deposits={data?.Geral?.Register || 0}
              CPA={data?.Geral?.CPA || 0}
              data={data}
            />
              <SalesAnalytics2
                data={data}
                dataDias={prepareSalesAnalyticsData(data)}
                dataColors='["--bs-primary","--bs-success","--bs-danger", "--bs-warning"]'
                dashboard={true}
            />

            </Row>

            <Row>
              <CandidateSection />
            </Row>

          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default DashboardJob
