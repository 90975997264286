import React, { useState, createContext, useEffect } from "react"

export const StateContext = createContext({})

const StateProvider = ({ children }) => {
  const [modal, setModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalEditNew, setModalEditNew] = useState(false)
  const [modalDel, setModalDel] = useState(false)
  const [editmodal, setEditModal] = useState(false)
  const [addValue, setValue] = useState(false)
  const [detailsValue, setDetailsValue] = useState(false)
  const [addValueSemanal, setAddValueSemanal ] = useState();
  const [contact, setContact] = useState(null);
  const [profileDataAvatar, setProfileDataAvatar] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [deleteInfluModal, setDeleteInfluModal] = useState(false)
  const [idInfluDelete, setIdInfluDelete] = useState(null)
  const [idInfluRemarketing, setIdInfluRemarketing] = useState(null)
  const [tema, setTema] = useState(null)
  const [modalRemarketing, setModalRemarketing] = useState(false)


  return (
    <StateContext.Provider value={{ modal, setModal, modalEdit, setModalEdit, setModalDel, modalDel, editmodal, setEditModal, addValue, setValue,
      detailsValue, setDetailsValue, addValueSemanal, setAddValueSemanal, modalEditNew, setModalEditNew, contact, setContact, profileDataAvatar, setProfileDataAvatar,
      profileData, setProfileData, deleteInfluModal, setDeleteInfluModal, idInfluDelete, setIdInfluDelete, tema, setTema, modalRemarketing, setModalRemarketing, idInfluRemarketing,
      setIdInfluRemarketing
    }}>
      {children}
    </StateContext.Provider>
  )
}

export default StateProvider
