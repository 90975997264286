import { StateContext } from "contexts/states";
import React, { useContext } from "react";
import api from "config/api"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { handleDeleteUser } from "../../../pages/Captacao/Contacts/ContactList/requests"
import { AuthContext } from "contexts/auth";
import './styles.css';

function DeleteInfluModal({ isOpen, attInflu }) {

  const { setDeleteInfluModal} = useContext(StateContext)
  const { user } = useContext(AuthContext);
  const {idInfluDelete, setIdInfluDelete} = useContext(StateContext)

  const handleDeleteUserConfirm = async () => {
    try {
      if (idInfluDelete) {
        await handleDeleteUser(idInfluDelete, user.token)
        attInflu()
      }
    } catch (error) {
      console.error("Erro ao excluir influenciador:", error)
    }
    setDeleteInfluModal(false);
  }

  const closeModal = () => {
    setDeleteInfluModal(false);
    setIdInfluDelete(null);
  };
  
  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader className="custom-modal-header" tag="h4" toggle={closeModal}>
            Confirmar Exclusão
        </ModalHeader>
            <ModalBody className="username2">Tem certeza de que você deseja excluir o influenciador?</ModalBody>
        <ModalFooter>
        <button className="button-cancelar" color="secondary" onClick={closeModal}>
            Cancelar
        </button>
        <button className="button-excluir" onClick={handleDeleteUserConfirm}>
            Excluir
        </button>
        </ModalFooter>
    </Modal>
  );
}

export default DeleteInfluModal;
