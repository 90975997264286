import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  InputGroupText,
  Label,
  Button,
} from "reactstrap"
import ChartSection from "./ChartSection"
import SalesAnalytics2 from "./sales-analytics"
import Section from "./Section"
import StatisticsApplications from "./StatisticsApplications"
import { AuthContext } from "contexts/auth"
import api from "config/api"
import CandidateSection from "./CandidateSection"
import Loading from "components/Ui/LoadingCircle"
import DatePicker from "react-flatpickr"

import "react-datepicker/dist/react-datepicker.css"

const DashboardJob = () => {
  const { user } = useContext(AuthContext)
  const [data, setData] = useState({})
  const [dataDay, setDataDay] = useState()
  const [meses, setMeses] = useState()
  const [loading, setLoading] = useState(true)
  const [selectedOption, setSelectedOption] = useState("todos")
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("Total")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [dataTotal, setDataTotal] = useState()

  const sumDataInRange = (dataArray, startDate, endDate) => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const totalSum = {}

    dataArray &&
      dataArray.forEach(item => {
        const itemDate = new Date(item.Period)

        if (itemDate >= start && itemDate <= end) {
          Object.keys(item).forEach(key => {
            if (typeof item[key] === "number") {
              totalSum[key] = (totalSum[key] || 0) + item[key]
            }
          })
        }
      })

    return totalSum
  }

  const getFirstAndCurrentDayOfCurrentMonth = () => {
    const now = new Date()

    // Primeiro dia do mês
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    const firstDayFormatted = firstDay.toISOString().split("T")[0]

    // Dia atual
    const currentDayFormatted = now.toISOString().split("T")[0]

    return {
      firstDay: firstDayFormatted,
      currentDay: currentDayFormatted,
    }
  }

  const handleStartDate = data => {
    const Day =
      new Date(data).getDate() < 10
        ? `0${new Date(data).getDate()}`
        : new Date(data).getDate()
    const Month =
      new Date(data).getMonth() + 1 < 10
        ? `0${new Date(data).getMonth() + 1}`
        : new Date(data).getMonth() + 1
    const Year =
      new Date(data).getFullYear() < 10
        ? `0${new Date(data).getFullYear()}`
        : new Date(data).getFullYear()
    setStartDate(`${Year}-${Month}-${Day}`)
  }
  const handleEndDate = data => {
    const Day =
      new Date(data).getDate() < 10
        ? `0${new Date(data).getDate()}`
        : new Date(data).getDate()
    const Month =
      new Date(data).getMonth() + 1 < 10
        ? `0${new Date(data).getMonth() + 1}`
        : new Date(data).getMonth() + 1
    const Year =
      new Date(data).getFullYear() < 10
        ? `0${new Date(data).getFullYear()}`
        : new Date(data).getFullYear()

    setEndDate(`${Year}-${Month}-${Day}`)
  }

  const handleFilterTotals = () => {
    const result = sumDataInRange(dataDay, startDate, endDate)
    setDataTotal(result)
  }

  function getMonthName(dateString) {
    if (
      typeof dateString !== "string" ||
      !/^(\d{4})-(\d{2})$/.test(dateString)
    ) {
      throw new Error("Data inválida. Use o formato AAAA-MM.")
    }

    const monthNames = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ]

    const [, monthNumber] = dateString.split("-")
    const monthIndex = parseInt(monthNumber, 10) - 1

    if (monthIndex < 0 || monthIndex >= monthNames.length) {
      throw new Error("Número do mês inválido.")
    }
    return monthNames[monthIndex]
  }

  useEffect(() => {
    async function getGraphics() {
      await api
        .get("/graphics/super/superbet", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          setData(response.data)
          setDataDay(response.data?.Day)

          setMeses(response.data.Meses)

          const { firstDay, currentDay } = getFirstAndCurrentDayOfCurrentMonth()

          setStartDate(firstDay)
          setEndDate(currentDay)

          const result = sumDataInRange(
            response.data?.Day,
            firstDay,
            currentDay
          )
          setDataTotal(result)

          setLoading(false)
        })
    }

    getGraphics()
  }, [user.token])

  const calculatePercentageChange = (current, previous) => {
    if (!previous) return 0;
    return ((current - previous) / Math.abs(previous)) * 100;
};

  const getChartData = () => {
    const depositsChange =
      data?.GraphicDeposit?.length > 1
        ? calculatePercentageChange(
            data.GraphicDeposit[data.GraphicDeposit.length - 1],
            data.GraphicDeposit[data.GraphicDeposit.length - 2]
          )
        : 0

    const registrationsChange =
      data?.GraphicsRegistrations?.length > 1
        ? calculatePercentageChange(
            data.GraphicsRegistrations[data.GraphicsRegistrations.length - 1],
            data.GraphicsRegistrations[data.GraphicsRegistrations.length - 2]
          )
        : 0

    const withdrawalsChange =
      data?.GraphicsFTD?.length > 1
        ? calculatePercentageChange(
            data.GraphicsFTD[data.GraphicsFTD.length - 1],
            data.GraphicsFTD[data.GraphicsFTD.length - 2]
          )
        : 0

    const commissionChange =
      data?.GraphicsCommissionTotal?.length > 1
        ? calculatePercentageChange(
            data.GraphicsCommissionTotal[
              data.GraphicsCommissionTotal.length - 1
            ],
            data.GraphicsCommissionTotal[
              data.GraphicsCommissionTotal.length - 2
            ]
          )
        : 0

    const commissionChangeRev =
      data?.GraphicsREV?.length > 1
        ? calculatePercentageChange(
            data.GraphicsREV[data.GraphicsREV.length - 1],
            data.GraphicsREV[data.GraphicsREV.length - 2]
          )
        : 0

    const commissionChangeCPA =
      data?.GraphicsCPA?.length > 1
        ? calculatePercentageChange(
            data.GraphicsCPA[data.GraphicsCPA.length - 1],
            data.GraphicsCPA[data.GraphicsCPA.length - 2]
          )
        : 0

    const monthlyTotals = Array.isArray(data?.MonthlyTotals)
      ? data.MonthlyTotals
      : []

    const filteredData =
      selectedOption !== "todos"
        ? monthlyTotals.filter(item => item.Period === selectedOption)
        : {}

    return [
      {
        id: 1,
        title: `Depósitos no Período`,
        price: dataTotal?.Deposits?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        perstangeValue: depositsChange.toFixed(2),
        bagdeColor: depositsChange >= 0 ? "success" : "danger",
        seriesData: [
          {
            name: "Depósitos",
            data: data?.GraphicDeposit,
            number: false,
          },
        ],
        color:
          depositsChange >= 0
            ? '["--bs-success", "--bs-transparent"]'
            : '["--bs-danger", "--bs-transparent"]',
      },
      {
        id: 2,
        title: `Registros no Período`,
        price: dataTotal?.Registrations?.toLocaleString(),
        perstangeValue: registrationsChange.toFixed(2),
        bagdeColor: registrationsChange >= 0 ? "success" : "danger",
        seriesData: [
          {
            name: "New Application",
            data: data?.GraphicsRegistrations,
            number: true,
          },
        ],
        color:
          registrationsChange >= 0
            ? '["--bs-success", "--bs-transparent"]'
            : '["--bs-danger", "--bs-transparent"]',
      },
      {
        id: 4,
        title: `FTDs no Período`,
        price: dataTotal?.FirstDepositCount?.toLocaleString("pt-BR"),
        perstangeValue: withdrawalsChange.toFixed(2),
        bagdeColor: withdrawalsChange >= 0 ? "success" : "danger",
        istrendingArrow: true,
        seriesData: [
          {
            name: "Total Rejected",
            data: data?.GraphicsFTD,
            number: false,
          },
        ],
        color:
          withdrawalsChange >= 0
            ? '["--bs-success", "--bs-transparent"]'
            : '["--bs-danger", "--bs-transparent"]',
      },
      {
        id: 5,
        title: `Comissão REV no Período`,
        price: dataTotal?.PercentCommission?.toLocaleString("pt-BR"),
        perstangeValue: commissionChangeRev.toFixed(2),
        bagdeColor: commissionChangeRev >= 0 ? "success" : "danger",
        istrendingArrow: true,
        seriesData: [
          {
            name: "Total Rejected",
            data: data?.GraphicsREV,
            number: false,
          },
        ],
        color:
          commissionChangeRev >= 0
            ? '["--bs-success", "--bs-transparent"]'
            : '["--bs-danger", "--bs-transparent"]',
      },
      {
        id: 6,
        title: `Comissão CPA no Período`,
        price: dataTotal?.CPACommission?.toLocaleString("pt-BR"),
        perstangeValue: commissionChangeCPA.toFixed(2),
        bagdeColor: commissionChangeCPA >= 0 ? "success" : "danger",
        istrendingArrow: true,
        seriesData: [
          {
            name: "Total Rejected",
            data: data?.GraphicsCPA,
            number: false,
          },
        ],
        color:
          commissionChangeCPA >= 0
            ? '["--bs-success", "--bs-transparent"]'
            : '["--bs-danger", "--bs-transparent"]',
      },
      {
        id: 3,
        title: `Comissão no Período`,
        price: dataTotal?.TotalCommission?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        perstangeValue: commissionChange.toFixed(2),
        bagdeColor: commissionChange >= 0 ? "success" : "danger",
        seriesData: [
          {
            name: "Total Approved",
            data: data?.GraphicsCommissionTotal,
            number: false,
          },
        ],
        color:
          commissionChange >= 0
            ? '["--bs-success", "--bs-transparent"]'
            : '["--bs-danger", "--bs-transparent"]',
      },
    ]
  }

  const generateMonths = () => {
    if (!Array.isArray(meses)) {
      return []
    }

    const monthNames = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ]

    return meses.map(month => {
      const [year, monthNumber] = month.split("-")
      const formattedMonth = monthNumber.padStart(2, "0")
      const monthLabel = monthNames[parseInt(monthNumber, 10) - 1]
      const label = `${monthLabel} ${year}`

      return { value: month, label }
    })
  }

  const chartsData = getChartData()

  const invertDateFormat = dateString => {
    const regex = /^\d{4}-\d{2}-\d{2}$/
    if (!regex.test(dateString)) {
      throw new Error("Data deve estar no formato YYYY-MM-DD")
    }
    const [year, month, day] = dateString.split("-")
    return `${day}/${month}/${year}`
  }

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <div className="page-content">
          <Container fluid style={{ marginTop: "-30px" }}>
            <Section />

            <Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Label for="dateRange">Selecionar Período:</Label>
                <Col lg={4}>
                  <div className="d-flex mb-3">
                    <DatePicker
                      value={startDate}
                      selected={startDate}
                      onChange={date => handleStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      className="form-control me-2"
                    />
                    <InputGroupText className="me-2">até</InputGroupText>
                    <DatePicker
                      value={endDate}
                      selected={endDate}
                      onChange={date => handleEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                    <Button
                      className="bg-primary ms-2"
                      onClick={() => handleFilterTotals()}
                    >
                      Filtrar
                    </Button>
                  </div>
                </Col>
              </div>
            </Row>

            <ChartSection chartsData={chartsData} />
            <Row>
              <StatisticsApplications
                Deposits={data?.GraphicDeposit}
                FTDs={data?.GraphicsFTDValue}
                Rev={data?.GraphicsREV}
                CPA={data?.GraphicsCPA}
                Commission={data?.GraphicsCommissionTotal}
                data={data}
              />
              <SalesAnalytics2
                data={data.Total}
                dataDias={data.MonthlyTotals}
                dataColors='["--bs-primary","--bs-success","--bs-danger", "--bs-warning"]'
                dashboard={true}
              />
            </Row>
            <Row>
              {/* <JobVacancy data={data} />  */}
              <CandidateSection />
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default DashboardJob
