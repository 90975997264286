import { useContext } from "react";
import { StateContext } from "contexts/states";

const cardStyles = () => {
  const { tema } = useContext(StateContext);

  return {
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: tema === "light" ? "#fff" : "#474d60",
    border: tema === "light" ? "1px solid #ddd" : "",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "10px",
    width: "300px",
    minWidth: "300px", 
    maxWidth: "300px",
    boxShadow: "0 2px 3px rgba(285, 255, 255, 0.5)",
    position: "relative",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 5px",
  },
  optionsSquare: {
    marginTop: "5px",
    color: "#000",
    marginRight: "5px",
    width: "30px",
    height: "32px",
    backgroundColor: "#dfdfdf",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    cursor: "pointer",
  },
  optionsSquare2: {
    marginTop: "5px",
    color: "#000",
    marginRight: "5px",
    width: "30px",
    height: "32px",
    backgroundColor: tema === "light" ? "#dfdfdf" : "#2c3343",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    cursor: "pointer",
  },
  whatsappButton: {
    marginRight: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "4px 6px",
    cursor: "pointer",
  },
  options: {
    display: "flex",
    alignItems: "center",
  },
  tagBar: {
    width: "85%",
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: tema === "light" ? "#dfdfdf" : "#2c3343",
    padding: "5px 5px",
    display: "flex",
    gap: "5px",
    justifyContent: "flex-start",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    minHeight: "30px"
  },
  tagBar2: {
    width: "72%",
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: tema === "light" ? "#dfdfdf" : "#2c3343",
    padding: "5px 5px",
    display: "flex",
    gap: "5px",
    justifyContent: "flex-start",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    minHeight: "30px"
  },
  tag: {
    backgroundColor: "#e0e0e0",
    color: "#000",
    fontSize: "12px",
    padding: "2px 6px",
    borderRadius: "4px",
    fontWeight: "bold",
    cursor: "pointer"
  },
  card: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  image: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: "10px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
  },
  name: {
    fontSize: "16px",
    fontWeight: "bold",
    margin: 0,
    color: tema === "light" ? "#333" : "#fff",
  },
  icon: {
    fontSize: "16px",
    color: "#E4405F",
    cursor: "pointer" 
  },
  usernameWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  username: {
    fontSize: "14px",
    margin: 0,
    color: tema === "light" ? "#555" : "#eaebed",
    cursor: "pointer",
  },
  dropdownMenu: {
    position: "absolute",
    top: "32%",
    right: "4%",
    backgroundColor: "#fff",
    padding: "0.5rem",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: 1,
  },
  dropdownMenuItem: {
    fontFamily: "'Inter', ui-sans-serif",
    fontWeight: "bold",
    color: "#4c4c4c",
    padding: "10px 15px",
    cursor: "pointer",
    fontSize: "12px",
    opacity: 1,
    visibility: "visible",
    borderBottom: "none",
  },
  dropdownMenuItemHover: {
    color: "#0aafc9",
    cursor: "pointer",
    opacity: 1,
    visibility: "visible",
    transition: "color 0.2s ease, opacity 0.3s ease",
    transitionDelay: "0.1s",
  },
  optionsSquareMenu: {
    cursor: "pointer",
    marginTop: "5px",
    color: tema === "light" ? "#000" : "#fff",
    marginRight: "5px",
    width: "30px",
    height: "32px",
    backgroundColor: tema === "light" ? "#dfdfdf" : "#2c3343",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    padding: "10px",
  },
}};

export default cardStyles;